import React, {Component} from 'react';
import {connect} from "react-redux";

class CursorComponent extends Component{
    cursor = React.createRef();
    cursorLazy = React.createRef();
    lastScrolledTop = 0;
    componentDidMount() {
        if(typeof window!=="undefined"){
            document.addEventListener('mousemove', this.moveAt);
            document.addEventListener('scroll', this.scrollingPage);
        }
    }


    moveAt = (e) => {
        let willTopSet = (e.pageY <= document.body.scrollHeight+110);
        let x = e.pageX
        let y = e.pageY

        if(e.pageY<=document.body.scrollHeight-110){
            document.querySelector(".cursor-block .eye-block").style.display=null;

        }else{
            document.querySelector(".cursor-block .eye-block").style.display="none";
        }
        if(e.pageY<=document.body.scrollHeight-10){
            //document.querySelector(".cursor-block .cursor-lazy").style.display=null;
        }else{
           // document.querySelector(".cursor-block .cursor-lazy").style.display="none";

        }
        if (this.cursor.current) {
            if(willTopSet){
                if(document.body.scrollHeight+60<e.pageY){
                    this.cursor.current.style.top = `${y - 29}px`;
                }else{
                    this.cursor.current.style.top = `${y - 29}px`;
                }
            }
            this.cursor.current.style.left = `${x - 29}px`;
        }
        if (this.cursorLazy.current) {
            if(willTopSet) {
                //this.cursorLazy.current.style.top = `${y + 1}px`;
            }
           // this.cursorLazy.current.style.left = `${x + 1}px`;
        }
    }

    scrollingPage = () => {
       // let yMousePos = this.cursorLazy.current.style.top.replace("px",'');
        if(this.lastScrolledTop != window.scrollY){
           // yMousePos -= this.lastScrolledTop;
            this.lastScrolledTop = window.scrollY;
           // yMousePos += this.lastScrolledTop;
        }
       // if(yMousePos <= document.body.scrollHeight+110){
       // this.cursorLazy.current.style.top = `${yMousePos}px`;
       // this.cursor.current.style.top = `${yMousePos - 30}px`;
      //  }
    }

    componentWillUnmount() {
        if(typeof window!=="undefined"){
            document.removeEventListener('mousemove', this.moveAt);
            document.removeEventListener('scroll', this.scrollingPage);
        }
    }
    /*TODO : show-play classı gelecek cursor-con'a*/
    render() {
        return(
            <div className="cursor-block" id={"cursorCon"}>
                <div className="eye-block" ref={this.cursor}>
                    <div className="eyes" >
                        <div className="shut">
                            <span className="closed"></span>
                        </div>
                        <div className="ball">

                        </div>
                    </div>
                </div>
                {/*<div id={"cursorLazy"} className="cursor-lazy" ref={this.cursorLazy}>*/}
                {/*</div>*/}
            </div>

        )
    }
}
const mapStateToProps = (state) => ({
    hoverImg: state.homeReducer
});

export default connect(mapStateToProps)(CursorComponent);
