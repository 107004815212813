import React,{Component} from "react";
import {ASSETS} from "../../constants/paths";
class LoaderComponent extends Component{
    loaderItem = React.createRef();
    componentDidMount() {
        typeof window !== undefined && document.body.classList.add("no-scroll");
    }
    componentWillUnmount() {
        typeof window !== undefined && document.body.classList.remove("no-scroll");

    }
    render() {
        return(
            <div className="loader-item" ref={this.loaderItem}>
                <img src={`${ASSETS}/img/loader.gif`}
                                 alt="Loading..."/>
            </div>

        )
    }
}

export default LoaderComponent;