import React, {Component} from 'react';
import {required} from "../../helper";

class InputFormComponent extends Component {

    render() {
        const {type,label,errorText,input,meta:{error,submitFailed}}=this.props;
        return (
                <div className={`input-item ${error && submitFailed ? "error" : ""}`} >
                    <input type={type}  {...input} className={`${error && submitFailed ? "error" : ""} ${input.value && input.value.length>0 ? "valid" : ""}`} />
                    {
                        label &&
                        <label>{label}</label>
                    }
                    <span className="error-txt">{errorText}</span>
                </div>
        );
    }
}


export default InputFormComponent;
