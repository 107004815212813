import * as actionTypes from '../constants/actionTypes';
import {saveFile,submitForm} from "../services/webService";

const changeActiveTag = (payload) => ({
    type: actionTypes.CHANGE_TAG,
    payload
});
export const changeActiveTagAction = (tag) => dispatch => {
    dispatch(changeActiveTag(tag))
};
const changeLang = (payload) => ({
    type: actionTypes.CHANGE_LANG,
    payload
});
export const changeActiveLangAction = (lang) => dispatch => {
    dispatch(changeLang(lang))
};
export const submitFormAction =(params,cb)=>dispatch=>{
    submitForm(params).then((res)=>{
        if (res.data.success) {
            cb(true);
        }else{
            cb(false);
        }
    })
}
export const saveFileAction = (cv) => dispatch => {
    return saveFile(cv);
};