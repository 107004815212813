import React, {Component} from 'react';
import FormComponent from '../components/formFields/formComponent';
import PageService from "../pageServices/pageService";
import {connect} from "react-redux";
import LoaderComponent from '../components/layout/loaderComponent';
class sayHelloPage extends Component {
    constructor(props) {
        super(props);
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    render() {
        let{bannerText,say_hello}=this.props;
        return (
            <div className="say-hello-root">
                { !bannerText && <LoaderComponent/>}
                <div className="say-hello-container">
                    <div className="say-hello-text-block">
                        {
                            bannerText &&
                            <div className="say-hello-text-item">
                                <div className="title-block">

                                    <h2 dangerouslySetInnerHTML={{__html:`${bannerText.title}`}}></h2>

                                    {/* <div className="eye-block">
                                        <div className="eyes">
                                            <div className="shut">
                                                <span className="closed"></span>
                                            </div>
                                            <div className="ball">

                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <p>{bannerText.desc}</p>

                            </div>
                        }
                    </div>
                    <FormComponent/>
                    <div className="hello-text-block">
                        <div className="hello-text-item">
                            <h2>
                                {say_hello}
                            </h2>
                        </div>
                        <div className="hello-info-item">
                            <a href="mailto:info@kollektif.com.tr"> info@kollektif.com.tr </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    bannerText: state.sayHelloReducer[state.LangReducer.lang]&&state.sayHelloReducer[state.LangReducer.lang].bannerText?state.sayHelloReducer[state.LangReducer.lang].bannerText : null,
    say_hello: state.sayHelloReducer[state.LangReducer.lang]&&state.sayHelloReducer[state.LangReducer.lang].say_hello?state.sayHelloReducer[state.LangReducer.lang].say_hello : null,
})
export default connect(mapStateToProps) (sayHelloPage);
