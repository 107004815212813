import {RECEIVE_KVKK} from '../constants/actionTypes'

const initialState = {};


const kvkkReducer = (state = initialState, action) => {
    switch(action.type){
        case RECEIVE_KVKK:
            return{
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default kvkkReducer;