import React, {Component} from 'react';
import ProjectItemComponent from "../home/projectItemComponent";
import {ASSETS} from "../../constants/paths";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";
class AwardsComponent extends Component {
    render() {
        let{top_title,top_shord_desc,awards,index}=this.props;
        return (
            <div className="awards-container">
                <div className="text-block">
                    <div className="text-item">
                        <h2>{top_title}</h2>
                        <p>{top_shord_desc}</p>
                    </div>

                </div>
                <div className="awards-block">
                    {
                        (index===0 && awards) && awards.map((item, key) =>
                            <div key={key} className="award-item">
                                <LazyLoad placeholder={<IMG/>}>
                                <picture>
                                    {/*<source  srcSet={`${ASSETS + item.img} 1x, ${ASSETS + item.img2x} 2x `}  type="image/png" />*/}
                                    <source srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`}
                                            type="image/png"/>
                                    <img src={`${ASSETS + item.img}`} alt={item.alt}/>
                                </picture>
                                </LazyLoad>
                                <div className="name-item" dangerouslySetInnerHTML={{__html: item.title}}>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        );
    }
}
export default AwardsComponent;