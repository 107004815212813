import React,{Component} from 'react';

class ModalVideoItemComponent extends Component{
    render() {
        let {videoId,closeVideoLb} = this.props;
        return(
            <div className="lb-root">
                <div className="lb-block">
                    <div className="lb-content-block ">
                        <div  className="close-btn" onClick={()=>{
                            closeVideoLb()}
                        }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                <g fill="none" fillRule="evenodd">
                                    <g fill="#000">
                                        <g transform="translate(7 7) rotate(45 1.06 2.56)">
                                            <rect width="34" height="3" rx="1"/>
                                        </g>
                                        <g transform="translate(7 7) rotate(-45 29.02 12.02)">
                                            <rect width="34" height="3" rx="1"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="iframe-block">
                            <iframe src={`https://www.youtube.com/embed/${videoId}`} frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen=""></iframe>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default ModalVideoItemComponent;