import React, {Component} from 'react';
import {Redirect, Route, Switch,withRouter} from "react-router-dom";
import * as ROUTES from "../constants/routePaths";
import HomePage from '../pages/homePage';
import {connect} from "react-redux";
import { changeActiveLangAction } from "../actions/index";
import {LANG_TR,LANG_EN} from "../constants/routePaths";
import {ENLANG, TRLANG} from "../constants/paths";
import HeaderComponent from "./layout/headerComponent";
import FooterComponent from "./layout/footerComponent";
import Paths from "../helper/path";
import ProjectDetailPage from "../pages/projectDetailPage";
import NotFoundPage from "../pages/notFoundPage";
import CursorComponent from "./common/cursorComponent";
import WhoAreWePage from "../pages/whoAreWePage";
import WorksPage from "../pages/worksPage";
import sayHelloPage from "../pages/sayHelloPage";
import cookiePage from "../pages/cookiePage";
import kvkkPage from "../pages/kvkkPage";

class RoutesComponent extends Component {
    paths=null;
    constructor(props) {
        super(props);
        this.checkLang(props);
    }
componentDidMount() {
}

pathGenerator(lang) {
    this.paths = new Paths(lang, ROUTES.routePaths);
}
    componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            let locationArr = nextProps.location.pathname.split("/");
            this.pathGenerator(locationArr[1]);
            this.props.dispatch(changeActiveLangAction(locationArr[1]));
        }
    }
checkLang = (props) => {
    let locationArr = this.props.location.pathname.split("/");
    if (locationArr[1] === ENLANG) {
        this.pathGenerator(ENLANG);
        this.props.dispatch(changeActiveLangAction(ENLANG));
    } else if (locationArr[1] === TRLANG) {
        this.pathGenerator(TRLANG);
        this.props.dispatch(changeActiveLangAction(TRLANG));
    } else if(locationArr[1] === "") {
        // this.props.history.push("/tr");
        this.pathGenerator(TRLANG);
    }else if (!locationArr[1] || locationArr[1].length === 0) {
        this.props.dispatch(changeActiveLangAction(TRLANG));
        // this.props.history.push("/tr");
        //this.pathGenerator(TRLANG);
    } else{
        locationArr.splice(1, 0, TRLANG);
        this.pathGenerator(TRLANG);
        // this.props.history.push("/tr/404");
    }
};
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.pathname!==this.props.location.pathname && typeof window!=="undefined" ){
            window.scrollTo({
                left:0,
                top:0
            })
        }
    }
render() {
    return (
        <div>
            <CursorComponent />
            <Switch>
            <Route component={HeaderComponent}/>
            </Switch>
            <Switch>
                {/* <Route exact path={"/"} component={HomePage}/> */}
                <Route exact path={this.paths.home} component={HomePage}/>
                <Route exact path={this.paths.project_detail} component={ProjectDetailPage}/>
                <Route exact path={this.paths.who_are_we} component={WhoAreWePage}/>
                <Route exact path={this.paths.brands} component={WhoAreWePage}/>
                <Route exact path={this.paths.works} component={WorksPage} />
                <Route exact path={this.paths.sayhello} component={sayHelloPage} />
                <Route exact path={this.paths.cookie} component={cookiePage} />
                <Route exact path={this.paths.kvkk} component={kvkkPage} />
                {/* <Route exact path={this.paths.not_found} component={NotFoundPage} /> */}
                <Route component={NotFoundPage}/>
            </Switch>
            <Switch>
            <Route component={FooterComponent}/>
            </Switch>
        </div>
);
}
}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
});

export default withRouter(connect(mapStateToProps)(RoutesComponent));