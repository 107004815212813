import '../src/assets/styles/main.css'
import RoutesComponent from "./components/routesComponent";

function App() {
    return (
        <div>
          <RoutesComponent/>
        </div>
    );
}

export default App;
