import {
    CHANGE_LANG,CHANGE_TAG
} from '../constants/actionTypes'

const initialState = {lang:"tr",activeTag:"all" };

const LangReducer = (state = initialState,action) => {

    switch (action.type){

        case CHANGE_LANG:
            return {
                ...state,
                lang:action.payload
            };
        case CHANGE_TAG:
            return {
                ...state,
                activeTag:action.payload
            };
        default:
            return state;
    }
};

export default LangReducer;
