import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";

class GalleryImageBlockComponent extends Component {
    render() {
        let{bottom_title,bottom_shord_desc,galleryImages}=this.props;
        return (
            <div className="detail-container">
                <div className="left-block">
                    <div className="detail-item">
                        <LazyLoad placeholder={<IMG/>}>
                        <picture>
                            {/*<source srcSet={`${ASSETS + galleryImages[0].img} 1x, ${ASSETS + galleryImages[0].img2x} 2x,`}  type="image/png" />*/}
                            {/*<source srcSet={`${ASSETS + galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf(".")) + ".webp"} 1x, ${ASSETS + galleryImages[0].img2x.substring(0, galleryImages[0].img2x.lastIndexOf(".")) + ".webp"} 2x,`}  type="image/webp" />*/}

                            <source media="(min-width: 768px)"
                                    srcSet={`${ASSETS}${galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf("."))}.webp 1x, ${ASSETS}${galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf("."))}_2x.webp 2x`}
                                    type="image/webp"/>
                            <source media="(min-width: 768px)"
                                    srcSet={`${ASSETS}${galleryImages[0].img} 1x, ${ASSETS}${galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf("."))}_2x${galleryImages[0].img.substring(galleryImages[0].img.lastIndexOf("."))} 2x`}
                                    type="image/jpeg"/>

                            <source media="(max-width: 767px)"
                                    srcSet={`${ASSETS}${galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf("."))}_mobile.webp 1x, ${ASSETS}${galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf("."))}_mobile_2x.webp 2x, ${ASSETS}${galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf("."))}_mobile_3x.webp 3x`}
                                    type="image/webp"/>
                            <source media="(max-width: 767px)"
                                    srcSet={`${ASSETS}${galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf("."))}_mobile${galleryImages[0].img.substring(galleryImages[0].img.lastIndexOf("."))} 1x, ${ASSETS}${galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf("."))}_mobile_2x${galleryImages[0].img.substring(galleryImages[0].img.lastIndexOf("."))} 2x, ${ASSETS}${galleryImages[0].img.substring(0, galleryImages[0].img.lastIndexOf("."))}_mobile_3x${galleryImages[0].img.substring(galleryImages[0].img.lastIndexOf("."))} 3x`}
                                    type="image/jpeg"/>


                            <img src={`${ASSETS + galleryImages[0].img}`} alt={galleryImages[0].alt}/>
                        </picture>
                        </LazyLoad>
                    </div>
                    <div className="detail-item">
                        <LazyLoad placeholder={<IMG/>}>
                        <picture>
                            {/*<source srcSet={`${ASSETS + galleryImages[1].img} 1x, ${ASSETS + galleryImages[1].img2x} 2x,`}  type="image/png" />*/}
                            {/*<source srcSet={`${ASSETS + galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf(".")) + ".webp"} 1x, ${ASSETS + galleryImages[1].img2x.substring(0, galleryImages[1].img2x.lastIndexOf(".")) + ".webp"} 2x,`}  type="image/webp" />*/}

                            <source media="(min-width: 768px)"
                                    srcSet={`${ASSETS}${galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf("."))}.webp 1x, ${ASSETS}${galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf("."))}_2x.webp 2x`}
                                    type="image/webp"/>
                            <source media="(min-width: 768px)"
                                    srcSet={`${ASSETS}${galleryImages[1].img} 1x, ${ASSETS}${galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf("."))}_2x${galleryImages[1].img.substring(galleryImages[1].img.lastIndexOf("."))} 2x`}
                                    type="image/jpeg"/>

                            <source media="(max-width: 767px)"
                                    srcSet={`${ASSETS}${galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf("."))}_mobile.webp 1x, ${ASSETS}${galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf("."))}_mobile_2x.webp 2x, ${ASSETS}${galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf("."))}_mobile_3x.webp 3x`}
                                    type="image/webp"/>
                            <source media="(max-width: 767px)"
                                    srcSet={`${ASSETS}${galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf("."))}_mobile${galleryImages[1].img.substring(galleryImages[1].img.lastIndexOf("."))} 1x, ${ASSETS}${galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf("."))}_mobile_2x${galleryImages[1].img.substring(galleryImages[1].img.lastIndexOf("."))} 2x, ${ASSETS}${galleryImages[1].img.substring(0, galleryImages[1].img.lastIndexOf("."))}_mobile_3x${galleryImages[1].img.substring(galleryImages[1].img.lastIndexOf("."))} 3x`}
                                    type="image/jpeg"/>

                            <img src={`${ASSETS + galleryImages[1].img}`} alt={galleryImages[1].alt}/>
                        </picture>
                        </LazyLoad>
                    </div>
                </div>
                <div className="right-block">
                    <div className="detail-item">
                        <LazyLoad placeholder={<IMG/>}>
                        <picture>
                            {/*<source srcSet={`${ASSETS + galleryImages[2].img} 1x, ${ASSETS + galleryImages[2].img2x} 2x,`}  type="image/png" />*/}
                            {/*<source srcSet={`${ASSETS + galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf(".")) + ".webp"} 1x, ${ASSETS + galleryImages[2].img2x.substring(0, galleryImages[2].img2x.lastIndexOf(".")) + ".webp"} 2x,`}  type="image/webp" />*/}

                            <source media="(min-width: 768px)"
                                    srcSet={`${ASSETS}${galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf("."))}.webp 1x, ${ASSETS}${galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf("."))}_2x.webp 2x`}
                                    type="image/webp"/>
                            <source media="(min-width: 768px)"
                                    srcSet={`${ASSETS}${galleryImages[2].img} 1x, ${ASSETS}${galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf("."))}_2x${galleryImages[2].img.substring(galleryImages[2].img.lastIndexOf("."))} 2x`}
                                    type="image/jpeg"/>

                            <source media="(max-width: 767px)"
                                    srcSet={`${ASSETS}${galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf("."))}_mobile.webp 1x, ${ASSETS}${galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf("."))}_mobile_2x.webp 2x, ${ASSETS}${galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf("."))}_mobile_3x.webp 3x`}
                                    type="image/webp"/>
                            <source media="(max-width: 767px)"
                                    srcSet={`${ASSETS}${galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf("."))}_mobile${galleryImages[2].img.substring(galleryImages[2].img.lastIndexOf("."))} 1x, ${ASSETS}${galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf("."))}_mobile_2x${galleryImages[2].img.substring(galleryImages[2].img.lastIndexOf("."))} 2x, ${ASSETS}${galleryImages[2].img.substring(0, galleryImages[2].img.lastIndexOf("."))}_mobile_3x${galleryImages[2].img.substring(galleryImages[2].img.lastIndexOf("."))} 3x`}
                                    type="image/jpeg"/>
                            <img src={`${ASSETS + galleryImages[2].img}`} alt={galleryImages[2].alt}/>
                        </picture>
                        </LazyLoad>
                    </div>

                    <div className="detail-item">
                        <div className="text-item">
                            <h2>{bottom_title}</h2>
                            <p>{bottom_shord_desc}</p>
                        </div>
                    </div>
                </div>



            </div>
        );
    }
}
export default GalleryImageBlockComponent;