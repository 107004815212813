import React, {Component} from 'react';
import {Link} from "react-router-dom";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";
import {addCursorConClass, removeCursorConClass} from "../../helper/index";
class OtherProjectItemComponent extends Component {
    onEnter = () => {
        if(this.props.showHover){
            //this.projectItem.current.style.cursor='none';
            //document.body.style.cursor='none';
            addCursorConClass("show");
        }
    }
    addClickAnimation = () => {
        if(this.props.showHover){
            addCursorConClass("anim");
        }
    }
    onLeave = () => {
        //this.projectItem.current.style.cursor='pointer';
        document.body.style.cursor=null;
        removeCursorConClass("show");
    }
    render() {
        let {activeIndex,index,item,activeLang}=this.props;
        return (
            <div className={`other-img-item ${activeIndex === index  ? "active" : ""}`}>
                <Link to={"/"+activeLang+(item.link?item.link:"/projeler/"+item.slug)} className="img-item" onMouseDown={this.addClickAnimation} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                    {
                        item.award &&
                        <div className="award-item">
                            <p>AWARD</p>
                        </div>
                    }
                    <LazyLoad placeholder={<IMG/>}>
                        <picture>
                            <source srcSet={`${item.listImgWebp}`} type="image/webp"/>
                            <source srcSet={`${item.listImg}`} type="image/png"/>
                            <img src={`${item.listImg}`} alt="products"/>
                        </picture>
                    </LazyLoad>
                </Link>
                <div className="other-bottom-block">
                    <span className="brand-item">{item.brand}</span>
                    <Link to={"/"+activeLang+(item.link?item.link:"/projeler/"+item.slug)}  className="name-item">{item.name}</Link>
                </div>
                <div className="tag-block">
                    {
                        item.tags&& item.tags.map((tag, key) =>
                            <a key={key} href="" className="tag-item"><span>{tag}</span></a>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default OtherProjectItemComponent;
