import React, {Component} from 'react';
import ReCAPTCHA from "react-google-recaptcha";

class RecaptchaFieldComponent extends Component {
    recaptchaRef = React.createRef();

    onRecaptchaChange = (value) => {
        this.props.input.onChange(value);
    };

    onRecaptchaExpired = () => {
        this.props.input.onChange(null);
    };

    render() {
        const {meta: {error, submitFailed}} = this.props;

        return (
            <div className={`recaptcha-wrapper ${error && submitFailed ? "error" : ""}`}>
                <ReCAPTCHA
                    ref={this.recaptchaRef}
                    sitekey={"6Ld6IA8cAAAAACOC2yk61inF7g4A0FP2woMGhiiF"}
                    onChange={this.onRecaptchaChange}
                    onExpired={this.onRecaptchaExpired}
                />
            </div>
        );
    }
}

export default RecaptchaFieldComponent;