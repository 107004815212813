export const HOME = "/:lang";
export const PROJECT_DETAIL = "/projeler";
export const SLASH_ROUTE = "/";
export const LANG_TR = "tr";
export const LANG_EN = "en";
export const routePaths = {
    trLang: "/tr",
    enLang: "/en",
    home: {
        routeName: "home",
        en: "/",
        tr: "/"
    },
    project_detail: {
        routeName: "projects",
        en: "/projeler/:detailLink",
        tr: "/projeler/:detailLink",
    },
    who_are_we: {
        routeName: "who_are_we",
        en: "/bizi-tani",
        tr: "/bizi-tani",
    },
    brands: {
        routeName: "brands",
        en: "/biz-tani/markalar",
        tr: "/biz-tani/markalar",
    },
    works: {
        routeName: "works",
        en: "/isler",
        tr: "/isler",
    },
    sayhello: {
        routeName: "sayhello",
        en: "/bize-yaz",
        tr: "/bize-yaz",
    },
    cookie: {
        routeName: "cookie",
        en: "/cerez-politikasi",
        tr: "/cerez-politikasi",
    },
    kvkk: {
        routeName: "kvkk",
        en: "/kvkk",
        tr: "/kvkk",
    },
    not_found: {
        routeName: "not_found",
        en: "/404",
        tr: "/404"
    },
}