import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {Link} from "react-router-dom";
import {addCursorConClass, removeCursorConClass} from "../../helper/index";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";
import Paths from "../../helper/path";
import * as ROUTES from "../../constants/routePaths";
import {changeActiveTagAction} from "../../actions";
import {connect} from "react-redux";
class ProjectItemPromoComponent extends Component {
    render() {
        let { item, index, activeLang } = this.props;
        return (
            <div className="project-item">
                            <div className="img-item" onMouseDown={this.addClickAnimation} >
                                <LazyLoad placeholder={<IMG/>}>
                        {item.link !=="" ?
                        <a href={item.link} target="_blank" className="cursor-none">
                        <picture>
                        <source media="(min-width: 768px)"
                            srcSet={`${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_2x.webp 2x`}
                            type="image/webp" />
                        <source media="(min-width: 768px)"
                            srcSet={`${ASSETS}${item.listImg} 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_2x${item.listImg.substring(item.listImg.lastIndexOf("."))} 2x`}
                            type="image/png" />

                        <source media="(max-width: 767px)"
                            srcSet={`${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile.webp 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_2x.webp 2x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_3x.webp 3x`}
                            type="image/webp" />
                        <source media="(max-width: 767px)"
                            srcSet={`${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile${item.listImg.substring(item.listImg.lastIndexOf("."))} 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_2x${item.listImg.substring(item.listImg.lastIndexOf("."))} 2x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_3x${item.listImg.substring(item.listImg.lastIndexOf("."))} 3x`}
                            type="image/png" />
                        {/*<source srcSet={`${item.listImgWebp}`} type="image/webp"/>*/}
                        {/*<source srcSet={`${item.listImg}`} type="image/png"/>*/}
                        <img src={`${ASSETS}${item.listImg}`} alt="products" />
                            </picture>
                        </a>:
                            <div className="" style={{ cursor:"default"}}>
                        <picture>
                        <source media="(min-width: 768px)"
                            srcSet={`${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_2x.webp 2x`}
                            type="image/webp" />
                        <source media="(min-width: 768px)"
                            srcSet={`${ASSETS}${item.listImg} 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_2x${item.listImg.substring(item.listImg.lastIndexOf("."))} 2x`}
                            type="image/png" />

                        <source media="(max-width: 767px)"
                            srcSet={`${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile.webp 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_2x.webp 2x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_3x.webp 3x`}
                            type="image/webp" />
                        <source media="(max-width: 767px)"
                            srcSet={`${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile${item.listImg.substring(item.listImg.lastIndexOf("."))} 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_2x${item.listImg.substring(item.listImg.lastIndexOf("."))} 2x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_3x${item.listImg.substring(item.listImg.lastIndexOf("."))} 3x`}
                            type="image/png" />
                        {/*<source srcSet={`${item.listImgWebp}`} type="image/webp"/>*/}
                        {/*<source srcSet={`${item.listImg}`} type="image/png"/>*/}
                        <img src={`${ASSETS}${item.listImg}`} alt="products" />
                            </picture>
                    </div>
                                }
                                </LazyLoad>
                            </div>
                            <div className="text-item" >
                                <p style={{ color: item.color }}>{item.name}</p>
                                {
                                    item.email &&
                                <a className="cursor-none" href={`mailto:${item.email}`} style={{color:item.color}}>{item.email}</a>
                                }
                                {
                                    item.desc &&
                                <span className="desc cursor-none"  style={{color:item.color}} dangerouslySetInnerHTML={{__html:`${item.desc}`}}></span>
                                }

                            </div>
                        </div>
        );
    }
}

export default ProjectItemPromoComponent;
