import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import LazyLoad from "react-lazyload";
import IMG from "../../components/common/lazyComponent";
class BrandItemComponent extends Component {
    
    render() {
        let {item} = this.props;
        return (
            <div className={this.props.isGray ? "brand-item gray" :"brand-item" }>
                <LazyLoad placeholder={<IMG/>}>
                <picture>
                    <source srcSet={`${ASSETS}/img/logos/${item}.webp 1x, ${ASSETS}/img/logos/${item}_2x.webp 2x`} type="image/webp"/>
                    <source srcSet={`${ASSETS}/img/logos/${item}.png 1x, ${ASSETS}/img/logos/${item}_2x.png 2x`} type="image/png"/>
                    <img src={`${ASSETS}/img/logos/${item}.png`} alt="products"/>
                </picture>
                </LazyLoad>
            </div>
        );
    }
}

export default BrandItemComponent;
