import React, { Component} from 'react';
import PageService from "../pageServices/pageService";
import { connect } from "react-redux";
import {ASSETS} from "../constants/paths";

class NotFoundPage extends Component {

    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }

    componentDidMount() {
    }

    render() {
        return (
            this.props.data?
                <div className="not-found-root">
                    <div className="content-block">
                        <div className="text-block">
                            <h1>{this.props.data.title}</h1>
                            <p dangerouslySetInnerHTML={{__html:`${this.props.data.desc}`}}></p>
                        </div>
                        <div className="video-block">
                            <video className="video-item" muted playsInline autoPlay loop>
                                <source src={`${ASSETS}/video/404.webm`} type="video/webm" />
                                <source src={`${ASSETS}/video/404.mp4`} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                </div>
                :
                <div> </div>
        );

    }
}


const mapStateToProps = (state, ownProps) => {
    return ({
        activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
        data: state.notFoundReducer &&state.LangReducer.lang && state.notFoundReducer[state.LangReducer.lang]?state.notFoundReducer[state.LangReducer.lang]:null
    });
};
export default connect(mapStateToProps)(NotFoundPage);
