import { RECEIVE_WORKS} from '../constants/actionTypes'

const initialState = {};


const worksReducer = (state = initialState, action) => {
    switch(action.type){
        case RECEIVE_WORKS:
            return{
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default worksReducer;