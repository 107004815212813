import {RECEIVE_NOT_FOUND} from '../constants/actionTypes'

const initialState = {};

const notFoundReducer = (state = initialState, action) => {
    switch(action.type){
        case RECEIVE_NOT_FOUND:
            return{
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default notFoundReducer;