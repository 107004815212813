import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/styles/main.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import thunk from "redux-thunk";
import {createLogger} from 'redux-logger'
import reducer from "./reducers";
import {applyMiddleware, createStore} from "redux";

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
}
let store = createStore(reducer, window.initialStoreData ? window.initialStoreData : {}, applyMiddleware(...middleware));
ReactDOM.hydrate(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
