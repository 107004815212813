import React, {Component} from 'react';
import {connect} from "react-redux";
import WhoAreWeItemComponent from "./whoAreWeItemComponent";
import BrandItemComponent from "../home/brandItemComponent";

class WhoAreWeWorksComponent extends Component {
    render() {
        return (
            <div className="who-we-img-block">
                {
                    this.props.items&& this.props.items.map((item, key) =>
                        <WhoAreWeItemComponent key={key} item={item}/>
                    )
                }

            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    items: state.whoAreWeReducer[state.LangReducer.lang]&&state.whoAreWeReducer[state.LangReducer.lang].items?state.whoAreWeReducer[state.LangReducer.lang].items : null,
})
export default connect(mapStateToProps) (WhoAreWeWorksComponent);