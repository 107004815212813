import React, {Component} from "react";
import {routePaths} from "../../constants/routePaths";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {ASSETS} from "../../constants/paths";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";
import CookiePolicyComponent from "../home/cookiePolicyComponent";

class FooterComponent extends Component {
   render() {
      let {layoutLang, activeLang} = this.props;
      return (
         <footer className="footer-root">
                                <CookiePolicyComponent/>

             <div className="footer-container safe-area">
                <div className="left-block">
                   <LazyLoad placeholder={<IMG/>}>
                      <picture>
                         <img src={`${ASSETS}/img/footer-logo@1x.png`} alt="footer logo"/>
                      </picture>
                   </LazyLoad>
                </div>
                <div className="right-block">

                   <div className="address-item">
                      <span>{layoutLang && layoutLang.addressTitle}</span>
                     <p>{ layoutLang && layoutLang.addressDetail}</p>
                      <a href="https://www.google.com/maps?ll=41.044432,28.986094&z=21&t=m&hl=tr&gl=TR&mapclient=embed&cid=4166965019127721627"
                         target="_blank" rel="noreferrer"
                         title="Kollektif Map">{layoutLang ? layoutLang.map : "Haritada Göster"}</a>
                   </div>
                   <div className="contact-block">
                      <div className="contact-item">
                         <span className="head">{layoutLang ? layoutLang.tel : "Telefon"}</span>
                         <a href="tel:02122617520">0212 261 75 20</a>
                         <span className="head">{layoutLang ? layoutLang.contact : "İletişim"}</span>
                         <a href="mailto:info@kollektif.com.tr" target="_blank"
                            rel="noreferrer">info@kollektif.com.tr</a>
                      </div>
                      <ul className="social-media-block">
                         <li>
                            <a className="instagram-icon" href="https://www.instagram.com/kollektiftr/"
                               target="_blank" rel="noreferrer"
                               title="Kollektif Instagram">
                               <svg width="26" height="26" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                    style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
                                  <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-46.0041" y1="634.1208"
                                                  x2="-32.9334" y2="647.1917"
                                                  gradientTransform="matrix(32 0 0 -32 1519 20757)">
                                     <stop offset={0} style={{stopColor: '#FFC107'}}/>
                                     <stop offset="0.507" style={{stopColor: '#F44336'}}/>
                                     <stop offset="0.99" style={{stopColor: '#9C27B0'}}/>
                                  </linearGradient>
                                  <path style={{fill: 'url(#SVGID_1_)'}} d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192
	c88.352,0,160-71.648,160-160V160C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112
	V160C48,98.24,98.24,48,160,48h192c61.76,0,112,50.24,112,112V352z"/>
                                  <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-42.2971" y1="637.8279"
                                                  x2="-36.6404" y2="643.4846"
                                                  gradientTransform="matrix(32 0 0 -32 1519 20757)">
                                     <stop offset={0} style={{stopColor: '#FFC107'}}/>
                                     <stop offset="0.507" style={{stopColor: '#F44336'}}/>
                                     <stop offset="0.99" style={{stopColor: '#9C27B0'}}/>
                                  </linearGradient>
                                  <path style={{fill: 'url(#SVGID_2_)'}} d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128
	S326.688,128,256,128z M256,336c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80
	C336,300.096,300.096,336,256,336z"/>
                                  <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-35.5456" y1="644.5793"
                                                  x2="-34.7919" y2="645.3331"
                                                  gradientTransform="matrix(32 0 0 -32 1519 20757)">
                                     <stop offset={0} style={{stopColor: '#FFC107'}}/>
                                     <stop offset="0.507" style={{stopColor: '#F44336'}}/>
                                     <stop offset="0.99" style={{stopColor: '#9C27B0'}}/>
                                  </linearGradient>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                               </svg>
                            </a>

                         </li>
                         <li>
                            <a className="facebook-icon" href="https://www.facebook.com/kollektif"
                               target="_blank" rel="noreferrer"
                               title="Kollektif Facebook">
                               <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                  <g fill="none" fillRule="evenodd">
                                     <g fill="#323232">
                                        <g>
                                           <g>
                                              <g>
                                                 <path
                                                     d="M26 13.08C26 5.855 20.18 0 13 0S0 5.856 0 13.08C0 19.607 4.754 25.018 10.969 26v-9.14H7.668v-3.78h3.3v-2.882c0-3.278 1.942-5.089 4.911-5.089 1.422 0 2.91.256 2.91.256v3.218h-1.64c-1.614 0-2.118 1.009-2.118 2.043v2.453h3.606l-.577 3.781h-3.029V26C21.246 25.019 26 19.608 26 13.08"
                                                     transform="translate(-1685 -4419) translate(98 4257) translate(1587 109) translate(0 53)"/>
                                              </g>
                                           </g>
                                        </g>
                                     </g>
                                  </g>
                               </svg>
                            </a>
                         </li>
                         <li>
                            <a className="linkedin-icon" href="https://www.linkedin.com/company/kollektif/"
                               target="_blank" rel="noreferrer"
                               title="Kollektif Linkedin">
                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22">
                                  <g fill="none" fillRule="evenodd">
                                     <g fill="#323232" fillRule="nonzero">
                                        <g>
                                           <g>
                                              <path
                                                  d="M24 13.488V22h-5.144v-7.942c0-1.995-.744-3.356-2.606-3.356-1.421 0-2.267.917-2.64 1.805-.135.317-.17.759-.17 1.203V22H8.294s.07-13.45 0-14.844h5.146V9.26c-.01.016-.024.033-.034.048h.034V9.26c.683-1.01 1.904-2.453 4.637-2.453 3.385 0 5.923 2.122 5.923 6.681zM2.912 0C1.152 0 0 1.108 0 2.564 0 3.99 1.118 5.13 2.843 5.13h.035c1.794 0 2.91-1.14 2.91-2.566C5.754 1.108 4.672 0 2.912 0zM.306 22h5.143V7.156H.306V22z"
                                                  transform="translate(-1686 -4479) translate(98 4257) translate(1587 109) translate(1 113)"/>
                                           </g>
                                        </g>
                                     </g>
                                  </g>
                               </svg>
                            </a>
                         </li>
                         <li>
                            <a className="twitter-icon" href="https://twitter.com/kollektif"
                               target="_blank" rel="noreferrer"
                               title="Kollektif Twitter">
                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
                                  <g fill="none" fillRule="evenodd">
                                     <g fill="#323232" fillRule="nonzero">
                                        <g>
                                           <g>
                                              <g>
                                                 <path
                                                     d="M24 2.368c-.883.402-1.832.672-2.828.794 1.017-.624 1.798-1.614 2.165-2.793-.951.578-2.005.999-3.127 1.226C19.313.613 18.032 0 16.616 0c-3.179 0-5.515 3.041-4.797 6.199C7.728 5.989 4.1 3.979 1.671.924c-1.29 2.27-.669 5.238 1.523 6.741-.806-.027-1.566-.253-2.229-.632-.054 2.34 1.581 4.528 3.949 5.015-.693.193-1.452.238-2.224.086.626 2.006 2.444 3.465 4.6 3.506-2.07 1.664-4.678 2.408-7.29 2.092C2.179 19.164 4.768 20 7.548 20c9.142 0 14.307-7.917 13.995-15.018.962-.713 1.797-1.602 2.457-2.614z"
                                                     transform="translate(-1686 -4542) translate(98 4257) translate(1587 109) translate(1 176)"/>
                                              </g>
                                           </g>
                                        </g>
                                     </g>
                                  </g>
                               </svg>
                            </a>
                         </li>
                         <li>
                            <a className="youtube-icon" href="https://www.youtube.com/user/KollektifAgency"
                               target="_blank" rel="noreferrer"
                               title="Kollektif Youtube">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
                              <g fill="none" fillRule="evenodd">
                              <g fill="#323232" fillRule="nonzero">
                              <path class="st0" d="M23,3.3c-0.3-1-1-1.8-2-2c-1.8-0.5-9-0.5-9-0.5s-7.2,0-9,0.5c-1,0.3-1.8,1-2,2C0.5,5.1,0.5,10,0.5,10
                                 s0,4.9,0.5,6.7c0.3,1,1,1.8,2,2c1.8,0.5,9,0.5,9,0.5s7.2,0,9-0.5c1-0.3,1.8-1,2-2c0.5-1.8,0.5-6.7,0.5-6.7S23.5,5.1,23,3.3z M9.7,13
                                 V7c0-0.4,0.5-0.7,0.9-0.5l5.2,3c0.4,0.2,0.4,0.8,0,1l-5.2,3C10.2,13.7,9.7,13.4,9.7,13z"/>
                                    </g>
                                    </g>
                              </svg>
                            </a>
                         </li>
                      </ul>

                   </div>

                </div>
             </div>
             <div className="bottom-block safe-area">
                <div className="bottom-left-area">
                   <span>{layoutLang ? layoutLang.allrgiht : "© KOLLEKTİF 2022 Tüm hakları saklıdır"}</span>
                </div>
                <div className="bottom-right-area">
                <Link to={`/${activeLang}${routePaths.kvkk[activeLang]}`}>{layoutLang ? layoutLang.privacy : "Gizlilik ve Kişisel Veri Politikası"}</Link>
                <Link to={`/${activeLang}${routePaths.cookie[activeLang]}`}>{layoutLang ? layoutLang.cookie : "Çerez Politikası"}</Link>
                </div>
             </div>
          </footer>
      )
   }
}

const mapStateToProps = (state) => ({
   activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
   layoutLang: state.LayoutReducer[state.LangReducer.lang] ? state.LayoutReducer[state.LangReducer.lang].footer : null
})

export default connect(mapStateToProps)(FooterComponent);
