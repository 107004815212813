import React, {Component} from 'react';
import InputFormComponent from "./inputFormComponent";
import {Field, initialize, reduxForm, reset} from "redux-form";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import TextAreaFormFieldComponent from "./textAreaFormFieldComponent";
//import RenderSelectComponent from "./renderSelectComponent";
//import RecaptchaFieldComponent from "./recaptchaFieldComponent";
import {saveFileAction, submitFormAction} from "../../actions/index";
import {maxLength} from "../../helper/index";
import RadioFieldComponent from "./radioFieldComponent";
//import FormRadioComponent from "./formRadioComponent";
import {ASSETS} from "../../constants/paths";
import RecaptchaFieldComponent from '../formInput/recaptchaFieldComponent';

const required = value => value ? undefined : 'Required';
const maxLength100 = maxLength(100);
const validate = (values, props) => {
    let errors = {};
    let hasErrors = false;
    console.log("values----", values);

    if (!/^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i.test(values.email)) {
        errors.email = " ";
        hasErrors = true;
    }
    if (!values || !values.recaptcha || values.recaptcha.trim() === "") {
        errors.recaptcha = "Required";
        hasErrors = true;
    }

    return hasErrors && errors;
};

class FormComponent extends Component {
    formContainer = React.createRef();
    fileName = null;
    recaptchaConfirmed = true;
    conditionChecked = true;
    conditionErr = false;
    addFileErr = false;
    sucsessModalOpen = false;
    value;
    state = {
        formType: "Contact"
    };

    changeFormType = (val) => {
        this.props.dispatch(initialize("AddContactForm", {}));
        this.conditionChecked = true;
        this.conditionErr = false;
        this.recaptchaConfirmed = true;
        this.addFileErr = false;
        window.grecaptcha.reset();
        this.sucsessModalOpen = false;
        this.forceUpdate();
    }
    submitForm = (values) => {
        console.log("VALUES---", values);
        this.loading = true;
        /* const response = window.grecaptcha.getResponse();
         console.log("VALUES---",values,response);
         this.loading = true;
         if (response.length == 0) {
             this.recaptchaConfirmed = false;
             this.forceUpdate();
         } else {*/


        //TODO: DOSYA KISMI GELİNCE AÇILACAK
        // if (!this.file) {
        //     //this.file = new File([], "no-file.pdf", {"type": "application/pdf"});
        //     if (this.fileName === null) {
        //         this.addFileErr = true;
        //         this.forceUpdate();
        //     }

        // } else {
        if (this.file && this.conditionChecked === true) {
            this.addFileErr = true;
            this.loading = true;
            this.forceUpdate();
            this.props.dispatch(saveFileAction(this.file)).then((res) => {
                    if (res.data.success) {
                        values["file_path"] = res.data.success;
                        this.props.dispatch(submitFormAction(values, (res) => {
                            if (res === true) {
                                this.props.dispatch(reset("AddContactForm"));
                                this.conditionChecked = true;
                                this.file = null;
                                this.fileName = null;
                                this.conditionErr = false;
                                this.recaptchaConfirmed = true;
                                this.sucsessModalOpen = true;
                                this.value = 5;
                                // window.grecaptcha.reset();
                                this.addFileErr = false;
                                this.loading = false;
                                this.countToHideModal();
                                this.forceUpdate();
                            }
                        }));
                        //  this.fileError = null;
                    } else {
                        if (res.data.status === 1) {
                            this.recatchValidation = null;
                            this.showErrorRecatcha = true;
                        }
                        this.fileError = "File Error";
                        this.file = null;
                    }
                    this.loading = false;
                    this.forceUpdate();
                }
            ).catch((e) => {
                console.log("error----", e);
            });
        } else {


            this.props.dispatch(submitFormAction(values, (res) => {
                if (res === true) {
                    this.props.dispatch(reset("AddContactForm"));
                    this.conditionChecked = true;
                    this.file = null;
                    this.fileName = null;
                    this.conditionErr = false;
                    this.recaptchaConfirmed = true;
                    this.sucsessModalOpen = true;
                    this.value = 5;
                    // window.grecaptcha.reset();
                    this.addFileErr = false;
                    this.loading = false;
                    this.countToHideModal();
                    this.forceUpdate();
                }
            }));

            this.conditionErr = this.conditionChecked !== true ? true : null;
            //this.fileError = this.file ? null : "File Error";
            //this.formError = "";
            this.forceUpdate();
        }
        //}
        // }
    };
    timer;
    countToHideModal = () => {
        clearTimeout(this.timer);
        this.value--;
        if (this.value > 0) {
            this.timer = setTimeout(this.countToHideModal, 5000);
        } else {
            this.sucsessModalOpen = false;
            this.setState({isButtonDisabled: false});
        }
        this.forceUpdate();
    };
    changeFile = (e) => {
        let typeArr = ["application/pdf", "application/msword", "image/jpeg", "image/png", "image/jpg"];
        if (e.target.files[0] && e.target.files[0].size < 5048576 && (typeArr.indexOf(e.target.files[0].type) > -1)) {
            this.file = e.target.files[0];
            this.fileName = e.target.files[0].name;
            this.fileError = null;
            this.fileTypeError = null;
            this.fileSizeError = null;
        } else if (typeArr.indexOf(e.target.files[0].type) === -1) {
            this.file = null;
            this.fileName = null;
            this.fileSizeError = null;
            this.fileTypeError = true;
        } else if (e.target.files[0].size >= 5048576) {
            this.file = null;
            this.fileName = null;
            this.fileTypeError = null;
            this.fileSizeError = true;
        } else {
            this.file = null;
            this.fileName = null;
            this.fileTypeError = null;
            this.fileSizeError = null;
            this.fileError = "File Error";
        }
        this.forceUpdate();
    };
    cancelUploadCV = () => {
        this.file = null;
        this.fileName = null;
        this.forceUpdate();
    };

    componentDidMount() {
        if (this.props.match.params.formName && this.props.contactData) {
            this.scrollToFormControl();
        }
    }

    componentDidUpdate(prevProps) {
        if (((!prevProps.contactData && this.props.contactData) || (prevProps.location.pathname != this.props.location.pathname)) && this.props.match.params.formName) {
            this.scrollToFormControl();
        }
    }

    scrollToFormControl = () => {
        if (window != undefined) {
            // window.scrollTo({left: 0, top: getElementPosition(this.formContainer.current).y - 100, behavior: 'smooth'});
        }
        this.setState({
            formType: (this.props.match.params.formName === "contact-form" ? "Contact" : "Job")
        })
    }

    render() {
        const {handleSubmit, formData, tags, submitFailed, contactData, requestCatalogData} = this.props;
        //console.log("SUBMİT FAİLED------",submitFailed);
        return (
            <div ref={this.formContainer} className="form-contact-root">
                {
                    formData &&
                    <form onSubmit={handleSubmit(this.submitForm)} className="form-root">
                        <div className="form-container">
                            <div className="input-container">
                                <div className="input-block">
                                    {
                                        tags &&
                                        <Field
                                            name="subject"
                                            //label={contactData.forms.approve}
                                            options={tags}
                                            component={RadioFieldComponent}
                                            validate={[required]}
                                        />
                                    }
                                    {
                                        <Field
                                            name="name"
                                            label={formData.name + "*"}
                                            type="text"
                                            placeholder={"adyaz"}
                                            component={InputFormComponent}
                                            validate={[required, maxLength100]}
                                        />
                                    }

                                    {
                                        <Field
                                            name="email"
                                            label={formData.email + "*"}
                                            type="text"
                                            placeholder={formData.email_placeholder}
                                            component={InputFormComponent}
                                            validate={[required]}
                                            errorText={formData.email_error_text}
                                        />
                                    }
                                    <Field
                                        name="description"
                                        label={formData.description}
                                        type="text"
                                        // placeholder="Açıklama"
                                        component={TextAreaFormFieldComponent}
                                        //validate={[required]}
                                    />

                                </div>

                                <Field
                                    name={`recaptcha`}
                                    component={RecaptchaFieldComponent}
                                />


                                <div className="add-file-block">
                                {   !this.fileName ?
                                    <div className={`add-file`}>
                                        <input type="file"
                                               onChange={this.changeFile}
                                               accept="application/pdf,application/msword,image/*"/>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" width="23" height="20"
                                             viewBox="0 0 23 20">
                                            <defs>
                                                <path id="ki57kqoira" d="M0 0L23 0 23 20 0 20z"/>
                                            </defs>
                                            <g fill="none" fillRule="evenodd">
                                                <g>
                                                    <g transform="translate(-429.000000, -1403.000000) translate(429.000000, 1403.000000)">
                                                        <mask id="24sept26sb" fill="#fff">
                                                            <use xlinkHref="#ki57kqoira"/>
                                                        </mask>
                                                        <path fill="#000"
                                                              d="M1.77 18.278c2.36 2.296 6.199 2.296 8.558 0l11.41-11.103c1.682-1.637 1.682-4.31 0-5.947-1.683-1.637-4.43-1.637-6.112 0L4.216 12.33c-1.005.977-1.005 2.59 0 3.569 1.004.977 2.662.977 3.667 0l9.78-9.518c.223-.211.314-.523.233-.817-.079-.293-.315-.521-.615-.598-.302-.078-.623.008-.84.227l-9.78 9.516c-.35.34-.874.34-1.224 0-.349-.34-.349-.85 0-1.19L16.848 2.418c1.027-.999 2.64-.999 3.667 0 1.027.998 1.027 2.57 0 3.569L9.105 17.088c-1.703 1.659-4.408 1.659-6.113 0-1.703-1.657-1.703-4.29 0-5.947l9.78-9.516c.225-.211.314-.524.235-.817-.079-.294-.315-.523-.616-.6-.302-.077-.623.008-.84.227l-9.78 9.516c-2.361 2.297-2.361 6.031 0 8.327"
                                                              mask="url(#24sept26sb)"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span className="btn-3">{formData.addFileStr}</span>
                                    </div>
                                    :
                                        <div className="upl-btn-cancel">
                                            <div className="file-name">
                                                <svg width="22" height="26" viewBox="0 0 22 26">
                                                    <defs>
                                                        <path id="a" d="M0 0h22v26H0z"/>
                                                    </defs>
                                                    <g fill="none" fillRule="evenodd">
                                                        <g>
                                                            <mask id="b" fill="#fff">
                                                                <use xlinkHref="#a"/>
                                                            </mask>
                                                            <path d="M1.258 5.001V1.353c0-.048.04-.088.088-.088H15.22v4.2c0 .747.604 1.355 1.346 1.355h4.176v17.826a.088.088 0 0 1-.088.089H1.346a.089.089 0 0 1-.088-.089V5.001zm15.22-2.841 1.687 1.697 1.688 1.697h-3.287a.088.088 0 0 1-.088-.088V2.16zM0 5.634v19.012C0 25.393.604 26 1.346 26h19.308c.742 0 1.346-.607 1.346-1.354V6.187c0-.014-.007-.026-.008-.04a.627.627 0 0 0-.032-.162c-.007-.02-.012-.04-.021-.06a.623.623 0 0 0-.123-.185L16.293.185a.62.62 0 0 0-.185-.124.628.628 0 0 0-.224-.054c-.011 0-.023-.007-.035-.007H1.346C.604 0 0 .607 0 1.353v4.281z" fill="#000" mask="url(#b)"/>
                                                        </g>
                                                        <path d="M4.64 10h14.72c.354 0 .64-.223.64-.5s-.286-.5-.64-.5H4.64c-.353 0-.64.223-.64.5s.287.5.64.5M4.64 14h14.72c.354 0 .64-.223.64-.5s-.286-.5-.64-.5H4.64c-.353 0-.64.223-.64.5s.287.5.64.5M4.64 18h14.72c.354 0 .64-.224.64-.5s-.286-.5-.64-.5H4.64c-.353 0-.64.224-.64.5s.287.5.64.5M4.64 22h14.72c.354 0 .64-.224.64-.5s-.286-.5-.64-.5H4.64c-.353 0-.64.224-.64.5s.287.5.64.5" fill="#000"/>
                                                    </g>
                                                </svg>
                                                <span>{this.fileName}</span>
                                            </div>
                                            <div className="cancel" onClick={() => {
                                                this.cancelUploadCV()
                                            }}>
                                                <span>
                                                <svg width="18" height="18" viewBox="0 0 18 18"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                    <g fill="none" fill-rule="evenodd">
                                                        <circle fill="#E17B7B" cx="9" cy="9" r="9"/>
                                                        <path fill="#FFF" d="m5.182 6.096 1.414-1.414 6.364 6.364-1.414 1.414z"/>
                                                        <path fill="#FFF" d="m11.546 4.682 1.414 1.414-6.364 6.364-1.414-1.414z"/>
                                                    </g>
                                                </svg>

                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <p className="file-limit" dangerouslySetInnerHTML={{__html:`${formData.file_limit}`}}></p>
                                <div className="input-bottom-block">
                                    <div className="input-item">
                                        <input className="submit-button" type="submit" value={formData.send}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                }
                {(this.loading === true) &&
                <div className="msgBlock">
                    <div className="contentItem">
                        <img src={`${ASSETS}/img/favicon.gif`}
                             alt="Loading..."/>
                    </div>
                </div>
                }
                {
                    this.sucsessModalOpen === true &&
                    <div className="oap-msg-item success">
                        <svg width="32" height="24" viewBox="0 0 32 24" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#38C775" fillRule="evenodd">
                                <path d="M.171 12 3 9.17l11.313 11.314-2.828 2.829z"/>
                                <path d="m28.485.686 2.828 2.829-19.799 19.799-2.828-2.829z"/>
                            </g>
                        </svg>
                        <span>{formData && formData.successJob}</span>
                    </div>
                }
                {/* {
                    this.recaptchaConfirmed === false &&
                    <div className="oap-msg-item">
                        <span>{contactData.forms.recapcha_required}</span>
                    </div>
                }*/}
                {
                    this.fileSizeError === true &&
                    <div className="oap-msg-item">
                        <span>{formData.over_limit}</span>
                    </div>
                }

                {
                    this.fileTypeError === true &&
                    <div className="oap-msg-item">
                        <span>{formData.disallowed_file_type}</span>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = reduxForm({
    form: "AddContactForm",
    validate
})(FormComponent);

export default withRouter(connect(state => (
    {
        formErrors: state.form.AddContactForm ? state.form.AddContactForm.syncErrors : {},
        activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
        formData: state.sayHelloReducer[state.LangReducer.lang] && state.sayHelloReducer[state.LangReducer.lang].form ? state.sayHelloReducer[state.LangReducer.lang].form : null,
        tags: state.sayHelloReducer[state.LangReducer.lang] && state.sayHelloReducer[state.LangReducer.lang].tags ? state.sayHelloReducer[state.LangReducer.lang].tags : null,
    }
))(mapStateToProps));

//export default ContactFormComponent;
