import React, {Component} from 'react';
//import * as ROUTES from "../constants/routePaths";
import PageService from "../pageServices/pageService";
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import BrandsComponent from "../components/home/brandsComponent";
import ProjectComponent from "../components/home/projectComponent";
import ProjectDetailInfoComponent from "../components/projectDetail/projectDetailInfoComponent";
import ProjectItemComponent from "../components/home/projectItemComponent";
import {Link} from "react-router-dom";
import BannerComponent from "../components/projectDetail/bannerComponent";
import GalleryComponent from "../components/projectDetail/galleryComponent";
import ImageComponent from "../components/projectDetail/imageComponent";
import OtherProjectComponent from "../components/projectDetail/otherProjectComponent";
import LazyLoad,{forceVisible} from "react-lazyload";
import ModalVideoItemComponent from '../components/modals/modalVideoItemComponent';
import modelDetailTween from "../helper/modelDetailTween";
import LoaderComponent from '../components/layout/loaderComponent';
class ProjectDetailPage extends Component {
    openVideo=React.createRef()
    closeVideo=React.createRef()
    isVideoModalOpen = false;
    constructor(props) {
        super(props);
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(props.dispatch, null, this.props.match.params);
        this.state={
            scrollHeight:0
        }
        
    }
    componentDidMount(){
        if(typeof window != undefined ){
            if(window.innerWidth>=767 ){
                if(this.openVideo && this.openVideo.current){
                    this.openVideo.current.style.opacity=0;
                }
                document.querySelector(".loader-bar").style.width=0;
                document.querySelector(".loader-bar").style.display="block";
                window.addEventListener("scroll",this.windowScrolling);
                setTimeout(()=>{
                    this.opacityAnimation();
                },3000)
            }
        }
    }
    componentWillUnmount(){
        if(typeof window != undefined ){
            if(window.innerWidth>=767 ){
            document.querySelector(".loader-bar").style.display="none";
            window.removeEventListener("scroll",this.windowScrolling);
            }
        }
    }
    windowScrolling=(e)=>{
        document.querySelector(".loader-bar").style.width=(this.state.scrollHeight===0 || ((window.scrollY*100)/this.state.scrollHeight)<=100) ?((window.scrollY*100)/this.state.scrollHeight+"%"):"100%";

    }
    
    componentDidUpdate(prevProps,prevState){
        if( this.props && this.openVideo && (prevProps.projectDetail !== this.props.projectDetail) && this.props.projectDetail.stage && this.props.projectDetail.stage[0]){
             let _height = this.openVideo.current.offsetHeight+((this.props.projectDetail.stage[0].singleImages.length-1)*1020)+(this.props.projectDetail.stage[0].galleryImages.length*1780);
             this.setState({scrollHeight:_height});
            }
    }
    opacityAnimation=()=>{
        let ModelDetailTweenItems = new modelDetailTween();
        ModelDetailTweenItems.opacityAnimation(this.openVideo.current);
    }
    render() {
        let {projectDetail, otherProjects, activeLang,other_projects} = this.props;
        

        return (
            <article className="project-detail-root" ref={this.openVideo}>
                {!projectDetail && <LoaderComponent/>}
                <div className="content-wrapper">
                    {
                        projectDetail &&
                        <ProjectDetailInfoComponent detail={projectDetail}/>
                    }
                    <div className="content-block">
                        {
                            projectDetail && projectDetail.stage && projectDetail.stage[0] && projectDetail.stage[0].banner &&
                            <BannerComponent banner={projectDetail.stage[0].banner}/>
                        }
                        {
                            projectDetail && projectDetail.stage && projectDetail.stage[0] &&  projectDetail.stage[0].galleryImages && projectDetail.stage[0].galleryImages[0] &&
                            <GalleryComponent detail={projectDetail} index={0}/>
                        }
                        {
                            projectDetail && projectDetail.stage && projectDetail.stage[0] && projectDetail.stage[0].singleImages && projectDetail.stage[0].singleImages[0] &&
                            <ImageComponent singleImages={projectDetail.stage[0].singleImages[0]}/>
                        }
                        {
                            projectDetail && projectDetail.stage && projectDetail.stage[0] &&  projectDetail.stage[0].galleryImages && projectDetail.stage[0].galleryImages[1] &&
                            <GalleryComponent detail={projectDetail} index={1}/>
                        }
                        {
                            projectDetail && projectDetail.stage && projectDetail.stage[0] && projectDetail.stage[0].singleImages && projectDetail.stage[0].singleImages[1] &&
                            <ImageComponent singleImages={projectDetail.stage[0].singleImages[1]}/>
                        }
                        {
                            projectDetail && projectDetail.stage && projectDetail.stage[0] &&  projectDetail.stage[0].galleryImages && projectDetail.stage[0].galleryImages[2] &&
                            <GalleryComponent detail={projectDetail} index={2}/>
                        }
                        {
                            projectDetail && projectDetail.stage && projectDetail.stage[0] && projectDetail.stage[0].singleImages && projectDetail.stage[0].singleImages[2] &&
                            <ImageComponent singleImages={projectDetail.stage[0].singleImages[2]}/>
                        }
                        <OtherProjectComponent activeLang={activeLang} projects={otherProjects} other_projects={ other_projects}/>
                    </div>
                </div>
            </article>
        );
    }
}

//export default connect()(ProjectDetailPage);
const mapStateToProps = (state, ownProps) => {
    let detail = null;
    let others = null;
    let other_projects = null;
    if (state.projectReducer[state.LangReducer.lang]) {
        detail = state.projectReducer[state.LangReducer.lang].find(x => x.slug === ownProps.match.params.detailLink)
        others = state.projectReducer[state.LangReducer.lang].filter(x => x.slug !== ownProps.match.params.detailLink)
        other_projects = state.LayoutReducer[state.LangReducer.lang].other_projects ? state.LayoutReducer[state.LangReducer.lang].other_projects : null
    }
    return ({
        activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
        otherProjects: others,
        projectDetail: detail,
        other_projects:other_projects
    });
};
export default withRouter(connect(mapStateToProps)(ProjectDetailPage));
