import React, {Component} from 'react';
import AwardsComponent from "./awardsComponent";
import GalleryImageBlockComponent from "./galleryImageBlockComponent";


class GalleryComponent extends Component {
    render() {
        let { detail, index } = this.props;
        return (
            <div className="gallery-container">
                {
                    detail.stage && detail.stage[0] &&  detail.stage[0].awards && detail.stage[0].galleryImages && detail.stage[0].galleryImages[index] &&
                    <AwardsComponent index={index} awards={detail.stage[0].awards} top_title={detail.stage[0].galleryImages[index].top_title} top_shord_desc={detail.stage[0].galleryImages[index].top_shord_desc}/>
                }
                {
                    detail.stage && detail.stage[0] &&  detail.stage[0].galleryImages && detail.stage[0].galleryImages[index] && detail.stage[0].galleryImages[index].gallery &&
                    <GalleryImageBlockComponent galleryImages={detail.stage[0].galleryImages[index].gallery} bottom_title={detail.stage[0].galleryImages[index].bottom_title} bottom_shord_desc={detail.stage[0].galleryImages[index].bottom_shord_desc}/>
                }
            </div>
        );
    }
}
export default GalleryComponent;