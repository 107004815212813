import React, {Component} from 'react';
import LazyLoad from "react-lazyload";
import IMG from "../common/lazyComponent";
import {ASSETS} from "../../constants/paths";

class WhoAreWeItemComponent extends Component {
    videoItem = React.createRef();
    componentDidMount() {
        if (typeof window !== undefined) {
            window.addEventListener("touchstart", this.handleStartVideo);
            window.addEventListener("scroll", this.handleStartVideo);
        }
    }

    handleStartVideo = () => {
        this.videoItem.current.play();
    }

    componentWillUnmount() {
        if (typeof window !== undefined) {
            window.removeEventListener("touchstart", this.handleStartVideo);
            window.removeEventListener("scroll", this.handleStartVideo);
        }
    }
    render() {
        let {item} = this.props;
        return (
            <div className="who-we-img-item">
                <div className="who-we-inside-item">
                    {
                        item && item.color &&
                        <div className="img-item" style={{ backgroundColor: `${item.color}` }}>
                            {item.isVideo ?
                                <video style={{ backgroundColor: `${item.color}` }} className="right-video" muted playsInline autoPlay ref={this.videoItem}
                                    //onLoadedMetadata={this.onVideoLoaded}
                                    onEnded={() => {
                                        this.videoItem.current.currentTime = 1;
                                        this.videoItem.current.play();
                                    }}>
                                    <source
                                        src={`${ASSETS}${item.video}`}
                                        type="video/webm" />
                                    <source
                                        src={`${ASSETS}${item.video.substring(0, item.video.lastIndexOf("."))}.mp4`}
                                        type="video/mp4" />
                                </video>
                                :
                                <LazyLoad placeholder={<IMG />}>
                                    <picture>
                                        <source srcSet={`${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x.webp 2x`}
                                            type="image/webp" />
                                        <source srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`}
                                            type="image/png" />
                                        <img src={`${ASSETS}${item.img}`} alt="products" />
                                    </picture>
                                </LazyLoad>
                            }
                        </div>
                    }
                    {
                        item &&
                        <div className="img-text-item">
                            <h3>{item.title}</h3>
                            <p>{item.desc}</p>
                        </div>
                    }
                    <div className="tag-block">
                        {
                            item && item.tags&& item.tags.map((tag, key) =>
                                <a key={key} href="" className="tag-item"><span>{tag}</span></a>
                            )
                        }
                    </div>
                </div>

            </div>
        );
    }
}

export default WhoAreWeItemComponent;