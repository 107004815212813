import React, {Component} from 'react';
import {connect} from "react-redux";
import ProjectItemComponent from "../home/projectItemComponent";
import CursorComponent from "../common/cursorComponent";
import modelDetailTween from "../../helper/modelDetailTween";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";
class ProjectComponent extends Component {
    titleItem = React.createRef();
    descItem = React.createRef();
    projectBlock = React.createRef();
    componentDidMount() {
         this.titleTween();
        this.itemsTween();
    }

    componentDidUpdate(prevProps, prevState) {

    }

    titleTween = () => {
        let ModelDetailTween = new modelDetailTween();
        let titleTween = ModelDetailTween.slideTextUpFadeIn(this.titleItem.current)
        let descTween = ModelDetailTween.slideTextUpFadeIn(this.descItem.current);
        ModelDetailTween.createTimeLine(null,[titleTween,descTween]);
    }
    itemsTween = () => {
        let ModelDetailTweenItems = new modelDetailTween();
        let scrollTrigger;
        let modeValue;
        if(typeof window !== "undefined" && window.innerWidth<840){
            scrollTrigger = ModelDetailTweenItems.createScrollTrigger(this.projectBlock.current,{start:"top+=150 bottom",end:`+=${this.projectBlock.current.offsetHeight}`,once:true});
            modeValue=2;
        }else{
            scrollTrigger = ModelDetailTweenItems.createScrollTrigger(this.projectBlock.current,{start:"top bottom",end:`+=${this.projectBlock.current.offsetHeight}`,once:true});
            modeValue=3
        }
        let animationArr = [];
        for(let i=0;i<this.projectBlock.current.children.length; i++){
            let tween;
            if(modeValue===3){
                if(i!==0&&i!==1&&i!==2){
                    if(i%modeValue===0){
                        tween = ModelDetailTweenItems.slideLeftFadeIn(this.projectBlock.current.children[i]);
                    }else if(i%modeValue===1){
                        tween = ModelDetailTweenItems.slideDownFadeIn(this.projectBlock.current.children[i]);
                    }else if(i%modeValue===2){
                        tween = ModelDetailTweenItems.slideRightFadeIn(this.projectBlock.current.children[i]);
                    }
                    // let tween = ModelDetailTween.slideLeftFadeIn(this.projectBlock.current.children[i]);
                    //animationArr.push({tween,position:(Math.floor((i)/modeValue)===Math.floor((i+1)/modeValue)&&Math.floor((i+1)/modeValue)===Math.floor((i+2)/modeValue))?"0":">"})
                    animationArr.push({tween,position:i===0||i===1||i===2?"0":Math.floor((i)/modeValue)})
                }

            }else if(modeValue===2){
                if(i!==0){
                    tween = ModelDetailTweenItems.slideUpFadeIn(this.projectBlock.current.children[i]);
                    animationArr.push({tween,position:i===0?"0":i})
                }
            }


        }
        //ModelDetailTweenItems.createTimeLine(scrollTrigger,animationArr);
        ModelDetailTweenItems.createTimeLineWithPosition(scrollTrigger,animationArr);
    }
    render() {
        return (
            <div className="project-root workPage">
                <div className="project-container" ref={this.projectBlock}>

                    {
                        this.props.activeTab==="all" || this.props.activeTab==="Tümü" || this.props.activeTab==="All"? this.props.projects&& this.props.projects.map((item, key) =>
                            <ProjectItemComponent key={key} showHover={true} activeLang={this.props.activeLang} index={key} item={item}/>
                        ) :
                            this.props.projects&& this.props.projects.filter(itm=>itm.tags&&itm.tags.includes(this.props.activeTab)).map((item, key) =>
                                <ProjectItemComponent key={key} showHover={true} activeLang={this.props.activeLang} index={key} item={item}/>
                            )
                    }
                    {/* <div className="widget-item project-block">
                        <div className="project-item">
                            <div className="img-item">
                                <LazyLoad placeholder={<IMG/>}>
                                <img src="/assets/img/projeyneticisi.png" alt="products"/>
                                </LazyLoad>
                            </div>
                            <div className="text-item">
                                <p>Proje Yöneticisi Arıyoruz</p>
                                <a href="">info@kollektif.com.tr</a>
                            </div>
                        </div>

                    </div> */}
                </div>

            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    projects: state.worksReducer[state.LangReducer.lang]&&state.worksReducer[state.LangReducer.lang].projects?state.worksReducer[state.LangReducer.lang].projects : null
})
export default connect(mapStateToProps)(ProjectComponent);
