import React, {Component} from 'react';
import {routePaths} from "../../constants/routePaths";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class CookiePolicyComponent extends Component {
    closeCookiePolicy = React.createRef()
    
    componentDidMount() {
        if (typeof window != undefined && localStorage.getItem("cookiePolicy"))
            this.closeCookie();
        else
            this.closeCookiePolicy.current.classList.remove("closed");

    }
    closeCookie= () =>{
        if (typeof window != undefined && !localStorage.getItem("cookiePolicy")) {
            this.closeCookiePolicy.current.classList.add("closed");
            localStorage.setItem("cookiePolicy", true);
        }

    }
    render() {
        let {activeLang} = this.props;
        return (
            <div className="cookie-policy-item closed" ref={this.closeCookiePolicy}>
                <p>Sitemizin çalışması için çerezler kullanılmaktadır. Detaylı bilgi için <Link to={`/${activeLang}${routePaths.cookie[activeLang]}`}>Çerez Politikası</Link>’nı inceleyebilirsiniz.</p>
                <svg onClick={()=>{
                    this.closeCookie()
                }}  xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <defs>
                        <filter id="egb7ol954a" color-interpolation-filters="auto">
                            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g filter="url(#egb7ol954a)" transform="translate(-1825.000000, -991.000000)">
                            <g>
                                <g fill="#000">
                                    <g transform="translate(1825.000000, 991.000000) translate(5.250000, 5.250000) translate(9.811107, 9.811107) rotate(45.000000) translate(-9.811107, -9.811107) translate(-2.938893, 8.686107)">
                                        <rect width="25.5" height="2.25" rx="1"/>
                                    </g>
                                    <g transform="translate(1825.000000, 991.000000) translate(5.250000, 5.250000) translate(9.811107, 9.811107) rotate(-45.000000) translate(-9.811107, -9.811107) translate(-2.938893, 8.686107)">
                                        <rect width="25.5" height="2.25" rx="1"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg >
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
})

export default connect(mapStateToProps)(CookiePolicyComponent);