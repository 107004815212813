import React, {Component} from 'react';
import OtherProjectItemComponent from "./otherProjectItemComponent";

class OtherProjectComponent extends Component {
    slideContainer=React.createRef();

    state = {
        activeIndex: 0
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            this.slideContainer.current.addEventListener('touchstart', this.handleTouchStart, false);

        }

        if (typeof window !== "undefined") {
            this.slideContainer.current.addEventListener('touchmove', this.handleTouchMove, false);

        }
    }

    componentWillUnmount() {

        if (typeof window !== "undefined") {
            this.slideContainer.current.removeEventListener('touchstart', this.handleTouchStart, false);
        }

        if (typeof window !== "undefined") {
            this.slideContainer.current.removeEventListener('touchmove', this.handleTouchMove, false);
        }
    }

    handleTouchStart = (evt) => {
        this.xDown = evt.touches[0].clientX;
        this.yDown = evt.touches[0].clientY;
    };
    handleTouchMove = (evt) => {
        if (!this.xDown || !this.yDown) {
            return;
        }
        this.xUp = evt.touches[0].clientX;
        this.yUp = evt.touches[0].clientY;


        let xDiff = this.xDown - this.xUp;
        let yDiff = this.yDown - this.yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            evt.preventDefault();
            if (xDiff > 0 ) {
                this.changeActiveIndex( 1);

            } else if (xDiff < 0) {
                this.changeActiveIndex(- 1);

            }
        }
        this.xDown = null;
        this.yDown = null;
    };
    changeActiveIndex = (direction) => {
        let elementLength = this.slideContainer.current.childElementCount;
        if (direction === -1 && this.state.activeIndex === 0) {
            this.setState({
                activeIndex:elementLength-1
            })
        } else if (direction === 1 && this.state.activeIndex === elementLength-1) {
            this.setState({
                activeIndex:0
            })
        }else{
            this.setState({
                activeIndex: this.state.activeIndex + direction
            })
        }

    }

    render() {
        let { projects,other_projects } = this.props;
        return (
            <div className="other-project-root">
                <div className="other-project-container">
                    <div className="other-text-item">
                        <span>{other_projects && other_projects}</span>
                    </div>

                    <div className="other-project-block" ref={this.slideContainer}>
                        {
                         projects && projects.sort(() => Math.random()-Math.random()).slice(0,3).map((item, key) =>
                                <OtherProjectItemComponent showHover={true} activeIndex={this.state.activeIndex} key={key}
                                                      activeLang={this.props.activeLang} index={key} item={item}/>
                            )
                        }
                    </div>

                    <div className="nav-btn-container">
                        <div className="nav-block">
                            {
                                projects && projects.slice(0,3).map((item, key) =>
                                    <span key={key} className={`${this.state.activeIndex === key ? "active" : ""}`}/>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default OtherProjectComponent;
