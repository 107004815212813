import React, {Component} from 'react';
//import * as ROUTES from "../constants/routePaths";
import PageService from "../pageServices/pageService";

import {connect} from "react-redux";
import BrandsComponent from "../components/home/brandsComponent";
import ProjectComponent from "../components/home/projectComponent";
import LoaderComponent from '../components/layout/loaderComponent';

class HomePage extends Component {
    pageService = null;
    _setTimeOut = null;
    state={
        isLoading:true
    }

    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    componentDidUpdate() {

        if(this.props.projects && this.props.bannerText){
            this._setTimeOut=setTimeout(()=>{
                this.setState({isLoading:false})
            },500)
        }
    }

    componentWillUnmount(){
        clearTimeout(this._setTimeOut)
    }



    render() {
        return (
            <div>
            {this.state.isLoading && <LoaderComponent/>}
            <div className="home-root safe-area">
                {
                    this.props.projects && this.props.bannerText &&  <ProjectComponent/>

                }
                    <BrandsComponent />

            </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    projects: state.homeReducer[state.LangReducer.lang]&&state.homeReducer[state.LangReducer.lang].projects?state.homeReducer[state.LangReducer.lang].projects : null,
    bannerText: state.homeReducer[state.LangReducer.lang]&&state.homeReducer[state.LangReducer.lang].banner_text?state.homeReducer[state.LangReducer.lang].banner_text : null
})
export default connect(mapStateToProps) (HomePage);
