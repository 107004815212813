export default class Paths {
    home = undefined;
    project_detail = undefined;
    who_are_we = undefined;
    works = undefined;
    brands=undefined;
    sayhello = undefined;
    not_found = undefined;
    cookie = undefined;
    kvkk = undefined;
    constructor(lang, data) {
       
        let arr = Object.keys(data);
        arr.forEach((val, index) => {
            if (this.hasOwnProperty(val)) {
                this[val] = data[lang + 'Lang'] + (data[val][lang] !== "/" ? data[val][lang] : "");
            }
        });
    }
}
