import React, {Component} from 'react';

class TextAreaFormFieldComponent extends Component {

    render() {
        const {type,label,placeholder,input,meta:{error,submitFailed}}=this.props;
        return (
            <div className={`input-item ${error && submitFailed ? "error" : ""}`} >
                <textarea placeholder={placeholder} {...input} className={`${error && submitFailed ? "error" : ""} ${input.value && input.value.length>0 ? "valid" : ""}`} name="message" ></textarea>

                {
                    label &&
                    <label>{label}</label>
                }
            </div>
        );
    }
}


export default TextAreaFormFieldComponent;
