import async from "async";
import * as webService from '../services/webService';
import * as actionTypes from "../constants/actionTypes";

const receiveNotFound = (payload) => ({
    type: actionTypes.RECEIVE_NOT_FOUND,
    payload
});

export const getNotFoundDataInit = (dispatch) => {
    return new Promise((resolve, failure) => {
        async.series([
            (cb) => {
                webService.getNotFound().then((res) => {
                    if (res.data.success) {
                        cb(null,res.data.success)
                    }else{
                        cb(null,false)
                    }
                }).catch((err) => {
                        // cb(null, false);
                    })
            }
        ], (err, result) => {
            dispatch(receiveNotFound(result[0]));
            resolve(result);
        });
    });


};



