import React, {Component} from 'react';
import {connect} from "react-redux";
import ProjectItemComponent from "./projectItemComponent";
import CursorComponent from "../common/cursorComponent";
import modelDetailTween from "../../helper/modelDetailTween";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";

class ProjectComponent extends Component {
    titleItem = React.createRef();
    descItem = React.createRef();
    projectBlock = React.createRef();

    rightEye = React.createRef();
    leftEye = React.createRef();

    componentDidMount() {
        if(this.projectBlock && this.projectBlock.current){
            this.projectBlock.current.style.opacity=0;
        }
        if (typeof window !== undefined){
            setTimeout(() => {
                
                this.titleTween();
            },2500)
            setTimeout(()=>{
                this.opacityAnimation();
            },3000)
        }
        if (typeof window !== undefined && window.innerWidth > 850) {
            setTimeout(()=>{
                this.itemsTween();
                this.opacityAnimation();
            },3000)
            document.addEventListener("mousemove", this.eyesMoving);
        }
        

    }

    componentDidUpdate(prevProps, prevState) {

    }

    titleTween = () => {
        let ModelDetailTween = new modelDetailTween();
        let titleTween = ModelDetailTween.slideTextUpFadeInFromTo(this.titleItem.current)
        let descTween = ModelDetailTween.slideTextUpFadeInFromTo(this.descItem.current);
        ModelDetailTween.createTimeLine(null, [titleTween, descTween]);
    }
    opacityAnimation=()=>{
        let ModelDetailTweenItems = new modelDetailTween();
        ModelDetailTweenItems.opacityAnimation(this.projectBlock.current);
    }
    itemsTween = () => {
        let ModelDetailTweenItems = new modelDetailTween();
        let scrollTrigger;
        let modeValue;
        ModelDetailTweenItems.opacityAnimation(this.projectBlock.current);
        if (typeof window !== "undefined" && window.innerWidth < 840) {
            scrollTrigger = ModelDetailTweenItems.createScrollTrigger(this.projectBlock.current, {
                start: "top+=150 bottom",
                end: `+=${this.projectBlock.current.offsetHeight}`,
                once: true
            });
            modeValue = 2;
        } else {
            scrollTrigger = ModelDetailTweenItems.createScrollTrigger(this.projectBlock.current, {
                start: "top bottom",
                end: `+=${this.projectBlock.current.offsetHeight}`,
                once: true
            });
            modeValue = 3
        }
        let animationArr = [];
        for (let i = 0; i < this.projectBlock.current.children.length; i++) {
            let tween;
            if (modeValue === 3) {
                if (i !== 0 && i !== 1 && i !== 2) {
                    if (i % modeValue === 0) {
                        tween = ModelDetailTweenItems.slideLeftFadeIn(this.projectBlock.current.children[i]);
                    } else if (i % modeValue === 1) {
                        tween = ModelDetailTweenItems.slideDownFadeIn(this.projectBlock.current.children[i]);
                    } else if (i % modeValue === 2) {
                        tween = ModelDetailTweenItems.slideRightFadeIn(this.projectBlock.current.children[i]);
                    }
                    animationArr.push({
                        tween,
                        position: i === 0 || i === 1 || i === 2 ? "0" : Math.floor((i) / modeValue)
                    })
                }
            } else if (modeValue === 2) {
                if (i !== 0) {
                    tween = ModelDetailTweenItems.slideUpFadeIn(this.projectBlock.current.children[i]);
                    animationArr.push({tween, position: i === 0 ? "0" : i})
                }
            }

        }
        ModelDetailTweenItems.createTimeLineWithPosition(scrollTrigger, animationArr);
    }

    eyesMoving = (event) => {

        if (typeof window!==undefined && this.leftEye.current && this.rightEye.current) {

            let x = event.clientX * 100 / window.innerWidth + "%";

            for (let i = 0; i < 2; i++) {

                this.leftEye.current.style.left = x;
                this.rightEye.current.style.left = x;
            }

        }
    }

    componentWillUnmount() {
        if (typeof window !== undefined) {
            document.removeEventListener("mousemove", this.eyesMoving);
        }
    }


    render() {
        return (
            <div className="project-root">
                <div className="head-block">
                    <div className="head-item">
                        <p ref={this.titleItem}>{this.props.bannerText}

                        </p>
                        <div ref={this.descItem} className="curios-block">
                            <div className="curios-item">
                                <div className="eye-item">
                                    <div className="ball" ref={this.leftEye}/>
                                </div>
                                <div className="eye-item">
                                    <div className="ball" ref={this.rightEye}/>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="project-container" ref={this.projectBlock}>

                    {
                        this.props.projects && this.props.projects.map((item, key) =>
                            <ProjectItemComponent key={key} showHover={true} activeLang={this.props.activeLang}
                                                  index={key} item={item}/>
                        )
                    }
                    {/* <div className="widget-item project-block">
                        <div className="project-item">
                            <div className="img-item">
                                <LazyLoad placeholder={<IMG/>}>
                                    <a href="">
                                        <img src="/assets/img/projeyneticisi.png" alt="products"/>
                                    </a>
                                </LazyLoad>
                            </div>
                            <div className="text-item">
                                <p>Proje Yöneticisi Arıyoruz</p>
                                <a href="">info@kollektif.com.tr</a>
                            </div>
                        </div>

                    </div> */}
                </div>

            </div>
        );


    }
}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    projects: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].projects ? state.homeReducer[state.LangReducer.lang].projects : null,
    bannerText: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].banner_text ? state.homeReducer[state.LangReducer.lang].banner_text : null
})

export default connect(mapStateToProps)(ProjectComponent);
