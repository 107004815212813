import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {Link} from "react-router-dom";
import {addCursorConClass, removeCursorConClass} from "../../helper/index";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";
import Paths from "../../helper/path";
import * as ROUTES from "../../constants/routePaths";
import {changeActiveTagAction} from "../../actions";
import { connect } from "react-redux";
import ProjectItemPromoComponent from './projectItemPromoComponent';
class ProjectItemComponent extends Component {
    upperItem=3;
    projectItem = React.createRef();
    videoAnimationRef = React.createRef();
    paths = null;
    videoAnimation = false;
    constructor(props) {
        super(props);
        this.paths = new Paths(props.activeLang, ROUTES.routePaths);
        if (typeof window !== "undefined"){
            this.upperItem=window.innerWidth<1201?2:3;
        }
    };

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.addEventListener("resize", this.resizeUpperItem);
            if(window.innerWidth<767)
            window.addEventListener("scroll",this.windowScrolling);
        }
    }
    componentDidUpdate(prevProps, prevState, prevContext) {
        if (prevProps&&this.props&&this.props.location&& prevProps.location&&(prevProps.location.pathname !== this.props.location.pathname)) {
            this.paths = new Paths(this.props.activeLang, ROUTES.routePaths);
            this.forceUpdate();
        }
    }
    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize",this.resizeUpperItem);
            window.removeEventListener("resize",this.windowScrolling);
            removeCursorConClass("anim");
            removeCursorConClass("show");
        }
    }
    resizeUpperItem=()=>{
        if(window.innerWidth<1201 && this.upperItem!==2){
            this.upperItem=2;
            this.forceUpdate();
        }else if(window.innerWidth>1201 && this.upperItem!==3){
            this.upperItem=3;
            this.forceUpdate();
        }
    };
    onEnter = () => {
        if(this.props.showHover){
            //this.projectItem.current.style.cursor='none';
            //document.body.style.cursor='none';
            addCursorConClass("show");
        }
        if (this.props.item && this.props.item.video)
            this.onAnimationStart();
    }
    addClickAnimation = () => {
        if(this.props.showHover){
            addCursorConClass("anim");
        }
    }
    onAnimationStart=()=>{
        this.videoAnimation = true;
        this.forceUpdate();
    }
    
    onAnimationStop=()=>{
        this.videoAnimation = false;
        this.forceUpdate();
    }

    onLeave = () => {
        //this.projectItem.current.style.cursor='pointer';
        document.body.style.cursor=null;
        removeCursorConClass("show");
        if (this.props.item && this.props.item.video)
            this.onAnimationStop();
    }
    changeActiveTab(tab){
        this.props.dispatch(changeActiveTagAction(tab));
        if(typeof window!=="undefined" ){
            window.scrollTo({
                left:0,
                top:0
            })
        }
    }
    windowScrolling = () => {
        this.onAnimationStart();
    }
    render() {
        let { item, index, activeLang } = this.props;
        return (
            <div className={`project-block ${index % this.upperItem === 1 ? "up" : ""} ${(index === this.props.activeIndex) ? "active" : ""} ${item.isPromo?"widget-item":""}`} >
                {!item.isPromo ?
                    <div className="project-item" ref={this.projectItem} >
                        <Link to={"/" + activeLang + (item.link ? item.link : "/projeler/" + item.slug)} className="img-item" onMouseDown={this.addClickAnimation}  onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                            {
                                    (item.award && item.award.img) &&
                                    <div className="award-item">
                                        <picture>
                                            <source media="(min-width: 768px)"
                                                srcSet={`${ASSETS}${item.award.img.substring(0, item.award.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.award.img.substring(0, item.award.img.lastIndexOf("."))}_2x.webp 2x`}
                                                type="image/webp" />
                                            <source media="(min-width: 768px)"
                                                srcSet={`${ASSETS}${item.award.img} 1x, ${ASSETS}${item.award.img.substring(0, item.award.img.lastIndexOf("."))}_2x${item.award.img.substring(item.award.img.lastIndexOf("."))} 2x`}
                                                type="image/png" />

                                            <source media="(max-width: 767px)"
                                                srcSet={`${ASSETS}${item.award.img.substring(0, item.award.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.award.img.substring(0, item.award.img.lastIndexOf("."))}_2x.webp 2x, ${ASSETS}${item.award.img.substring(0, item.award.img.lastIndexOf("."))}_3x.webp 3x`}
                                                type="image/webp" />
                                            <source media="(max-width: 767px)"
                                                srcSet={`${ASSETS}${item.award.img.substring(0, item.award.img.lastIndexOf("."))}${item.award.img.substring(item.award.img.lastIndexOf("."))} 1x, ${ASSETS}${item.award.img.substring(0, item.award.img.lastIndexOf("."))}_2x${item.award.img.substring(item.award.img.lastIndexOf("."))} 2x, ${ASSETS}${item.award.img.substring(0, item.award.img.lastIndexOf("."))}_3x${item.award.img.substring(item.award.img.lastIndexOf("."))} 3x`}
                                                type="image/png" />
                                            <img src={`${ASSETS}${item.award.img}`} alt="Ödül" />
                                        </picture>
                                    </div>
                            }
                            {item.video && item.video != "" && this.videoAnimation === true ?
                                <video className="right-video" muted playsInline autoPlay ref={this.videoAnimationRef}
                                    //onLoadedMetadata={this.onVideoLoaded}
                                    onEnded={() => {
                                        this.videoAnimationRef.current.play();
                                    }}>
                                    <source
                                        src={`${ASSETS}${item.video}-web.webm`}
                                        type="video/webm" />
                                    <source
                                        src={`${ASSETS}${item.video}-web.mp4`}
                                        type="video/mp4" />
                                </video>
                                :
                                <LazyLoad placeholder={<IMG />}>
                                    <picture>
                                        <source media="(min-width: 768px)"
                                            srcSet={`${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_2x.webp 2x`}
                                            type="image/webp" />
                                        <source media="(min-width: 768px)"
                                            srcSet={`${ASSETS}${item.listImg} 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_2x${item.listImg.substring(item.listImg.lastIndexOf("."))} 2x`}
                                            type="image/png" />

                                        <source media="(max-width: 767px)"
                                            srcSet={`${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile.webp 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_2x.webp 2x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_3x.webp 3x`}
                                            type="image/webp" />
                                        <source media="(max-width: 767px)"
                                            srcSet={`${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile${item.listImg.substring(item.listImg.lastIndexOf("."))} 1x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_2x${item.listImg.substring(item.listImg.lastIndexOf("."))} 2x, ${ASSETS}${item.listImg.substring(0, item.listImg.lastIndexOf("."))}_mobile_3x${item.listImg.substring(item.listImg.lastIndexOf("."))} 3x`}
                                            type="image/png" />
                                        {/*<source srcSet={`${item.listImgWebp}`} type="image/webp"/>*/}
                                        {/*<source srcSet={`${item.listImg}`} type="image/png"/>*/}
                                        <img src={`${ASSETS}${item.listImg}`} alt="products" />
                                    </picture>
                                </LazyLoad>
                            }
                            </Link>
                        
                        <div className="bottom-block">
                            <span className="brand-item">{item.brand}</span>
                            <Link to={"/" + activeLang + (item.link ? item.link : "/" + item.slug)} className="name-item">{item.name}</Link>
                            <div className="tag-block">
                                {
                                    item.tags && item.tags.map((tag, key) =>
                                        <Link key={key} onClick={() => { this.changeActiveTab(tag); }} to={this.paths.works} className="tag-item"><span>{tag}</span></Link>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <ProjectItemPromoComponent item={item}/>

                        }
            </div>
        );
    }
}

export default connect()(ProjectItemComponent);
