import React, {Component} from 'react';
import {API_URL, ASSETS} from "../../constants/paths";
import IMG from "../common/lazyComponent";
import LazyLoad from "react-lazyload";
import ReactPlayer from "react-player";
class BannerComponent extends Component {
        reactVideoRef = React.createRef();
        pauseIconRef = React.createRef();
        state = {
                playing: true
        }
        handlePause=()=>{
                this.setState({ playing: false })
                this.pauseIconRef.current.classList.remove("hide");
        }
        handleStart=()=>{
                this.setState({ playing: true })
               this.pauseIconRef.current.classList.add("hide");
        }
    render() {
            let { banner } = this.props;
        return (
            <div className="banner-block">
                       
                    {
                         (banner && banner.isVideo) ?
                        <div className="player-wrapper">
                                <svg className="hide pause-icon" onClick={this.handleStart} ref={this.pauseIconRef} width="101" height="101" viewBox="0 0 101 101" xmlns="http://www.w3.org/2000/svg"> <g fill="none" fillRule="evenodd"> <path fill="#FFF" d="M0 0h101v101H0z"/> <g fill="#323232"> <path d="M40 36h7.5v30H40zM54.5 36H62v30h-7.5z"/> </g> </g> </svg>
                                                <ReactPlayer muted={banner.locale?true:false}  onPlay={this.handleStart} playing={this.state.playing} ref={this.reactVideoRef} className="react-player" width="100%" height="100%" controls  onPause={this.handlePause}  url={banner.video_full_link}  light={banner.locale?false:`${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_2x.webp`}  playIcon={<svg xmlns="http://www.w3.org/2000/svg" width="101" height="101" viewBox="0 0 101 101"><g fill="none" fillRule="evenodd"><g><g><path fill="#FFF" d="M0 0H101V101H0z" transform="translate(-910.000000, -1228.000000) translate(910.000000, 1228.000000)"/><path fill="#323232" d="M52 37L66 65 38 65z" transform="translate(-910.000000, -1228.000000) translate(910.000000, 1228.000000) translate(52.000000, 51.000000) rotate(-270.000000) translate(-52.000000, -51.000000)"/></g> </g> </g> </svg>}/>
                        </div>
                    
                              :
                <LazyLoad placeholder={<IMG/>}>
                <picture>
                    <source media="(min-width: 768px)"
                            //srcSet={`${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_2x.webp 2x`}
                            srcSet={`${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}.webp 1x`}
                            type="image/webp"/>
                    <source media="(min-width: 768px)"
                            //srcSet={`${ASSETS}${banner.img} 1x, ${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_2x${banner.img.substring(banner.img.lastIndexOf("."))} 2x`}
                            srcSet={`${ASSETS}${banner.img} 1x`}
                            type="image/jpeg"/>

                    <source media="(max-width: 767px)"
                            srcSet={`${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_mobile.webp 1x, ${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_mobile_2x.webp 2x, ${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_mobile_3x.webp 3x`}
                            type="image/webp"/>
                    <source media="(max-width: 767px)"
                            srcSet={`${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_mobile${banner.img.substring(banner.img.lastIndexOf("."))} 1x, ${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_mobile_2x${banner.img.substring(banner.img.lastIndexOf("."))} 2x, ${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_mobile_3x${banner.img.substring(banner.img.lastIndexOf("."))} 3x`}
                            type="image/jpeg"/>

                    <img  src={`${ASSETS + banner.img}`} alt="Kollektif"/>
                </picture>
                </LazyLoad>
    }
            </div>
        );
    }
}
export default BannerComponent;