import {RECEIVE_WHO_ARE_WE} from '../constants/actionTypes'

const initialState = {};


const whoAreWeReducer = (state = initialState, action) => {
    switch(action.type){
        case RECEIVE_WHO_ARE_WE:
            return{
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default whoAreWeReducer;