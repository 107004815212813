import Paths from "./path";
import {matchPath} from "react-router-dom";
import * as ROUTES from "../constants/routePaths";


export const  required = value => value && value.trim() !== "" ? undefined : 'Required';

export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined
export const removeCursorConClass = (className = "hide-active") => {
    if(typeof window !== "undefined"){
        let cursorItem = document.getElementById("cursorCon");
        cursorItem.classList.remove(className);
    }
}

export const addCursorConClass = (className = "hide-active") => {
    if(typeof window !== "undefined"){
        let cursorItem = document.getElementById("cursorCon");
        cursorItem.classList.add(className);
    }
}
export const getLangLink = (activeLang, location,lng) => {
    let locationArr = location.pathname.split("/");
    let activePaths = new Paths(activeLang, ROUTES.routePaths);

    // let deActiveLang = activeLang === "tr" ? "en" : "tr";
    //  console.log("-------",activeLang,"------",lng,"---",locationArr);
    let deActiveLang = lng.toLowerCase();
    let deActivePaths = new Paths(deActiveLang, ROUTES.routePaths);
    if (locationArr[3]) {
        let routeKey = "";
        let returnUrl=null;
        Object.keys(activePaths).map((key) => {
            let urlParams = matchPath(location.pathname, {
                path: activePaths[key],
                exact: false,
                strict: false
            });
            // console.log("activePaths[key]-------",activePaths[key],"-------urlparams-----",urlParams);
            let comparePath= urlParams!==null?urlParams.url:activePaths[key];

            if (comparePath === location.pathname) {
                returnUrl=urlParams!==null?urlParams.url:key;
                routeKey = returnUrl;
            }
            // console.log("returnUrl-*--------------------",returnUrl);
            return returnUrl;
        });
        // console.log("---location.pathname----",location.pathname,"---deActivePaths----",deActivePaths,"---routeKey---",routeKey,"-----locationArr---",locationArr);
        if(returnUrl !== null){
            return returnUrl.replace("/"+activeLang+"/","/"+lng+"/");
        }else{
            return deActivePaths[routeKey] ? deActivePaths[routeKey] : deActivePaths.notFound;
        }

    } else if (locationArr[2]) {
        let routeKey = "";
        Object.keys(activePaths).map((key) => {
            if (activePaths[key] === location.pathname || activePaths[key] === location.pathname) {
                routeKey = key;
            }
            return key;
        });
        return deActivePaths[routeKey] ? deActivePaths[routeKey] : deActivePaths.notFound
    } else {
        return "/" + deActiveLang;
    }
};
export const getElementPosition = (el) => {
    var xPos = 0;
    var yPos = 0;
    while (el) {
        if (el.tagName === "BODY") {
            xPos += (el.offsetLeft + el.clientLeft);
            yPos += (el.offsetTop + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
    }
    return {
        x: xPos,
        y: yPos
    };
};
export const scrollToTop = ()=>{
    if(window.pageYOffset > 0){
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
    }
}
