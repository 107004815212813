import React,{Component} from 'react';

class RadioFieldComponent extends Component{
    render() {
        let {input,options,meta:{error,submitFailed}} = this.props;
        console.log("OPTİONS--------",this.props.options);
        return(
            <div className={`tag-block ${error && submitFailed ? "error" : ""}`}>
                {
                    /*options.map((optionItem,key)=>
                        <div key={key} className={"radio-item "+(optionItem.value===input.value?"active":"")} onClick={()=>{
                            input.onChange(optionItem.value)
                        }}>
                            <span></span>
                            <input onChange={()=>{}} checked={optionItem.value==input.value} autoComplete="off" type="radio" name={input.name} />
                            <label htmlFor="">{optionItem.name}</label>
                        </div>
                    )*/

                    options.map((optionItem,key)=>
                    <div key={key} className={"tag-item "+(optionItem===input.value?"active":"")} onClick={()=>{
                    input.onChange(optionItem)
                }}>
                    <span></span>
                    <input onChange={()=>{}} checked={optionItem==input.value} style={{visibility:"hidden"}} autoComplete="off" type="radio" name={input.name} />
                    <label htmlFor="">{optionItem}</label>
                    </div>
                    )
                }
            </div>
        )
    }
};

export default RadioFieldComponent;
