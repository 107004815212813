import React, {Component} from 'react';
import PageService from "../pageServices/pageService";
import {connect} from "react-redux";
import ProjectComponent from "../components/works/projectComponent";
import {changeActiveTagAction} from "../actions";
import {TRLANG} from "../constants/paths";
import LoaderComponent from '../components/layout/loaderComponent';


class WorksPage extends Component {
    // state={
    //     activeTab:"all"
    // }
    changeActiveTab(tab){
        this.props.dispatch(changeActiveTagAction(tab));
       // this.setState({activeTab:tab});
    }
    constructor(props) {
        super(props);
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    render() {
        return (
            <div className="works-root safe-area">
                {!(this.props.bannerText && this.props.tags) && <LoaderComponent/>}
                <div className="works-container">
                    <div className="works-text-item">
                        <span>{this.props.bannerText}</span>
                    </div>
                    <div className="tag-block">

                        {
                            this.props.tags&& this.props.tags.map((item, key) =>
                                <div key={key } onClick={()=>{this.changeActiveTab(item)}} className={`tag-item ${item===this.props.activeTag?"active":""}`}>
                                    <span>{item}</span>
                                </div>
                            )
                        }
                    </div>
                </div>
                <ProjectComponent activeTab={this.props.activeTag}/>
            </div>

        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    activeTag: state.LangReducer.activeTag ? state.LangReducer.activeTag : null,
    bannerText: state.worksReducer[state.LangReducer.lang]&&state.worksReducer[state.LangReducer.lang].banner_text?state.worksReducer[state.LangReducer.lang].banner_text : null,
    tags: state.worksReducer[state.LangReducer.lang]&&state.worksReducer[state.LangReducer.lang].tags?state.worksReducer[state.LangReducer.lang].tags : null,
})
export default connect(mapStateToProps) (WorksPage);