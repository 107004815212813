import {gsap} from "gsap";
import {EASE_TYPE_ONE, DURATION_NORMAL, DURATION_FAST, EASE_TYPE_TWO, EASE_TYPE_THREE} from "../constants/tweenConstants";

let ScrollTrigger;

if (typeof window !== "undefined") {
    ScrollTrigger = require("gsap/ScrollTrigger")
}

class ModelDetailTween {
    constructor() {
        if (ScrollTrigger) {
            gsap.registerPlugin(ScrollTrigger);
        }
    }

    createTimeLine(scrollTrigger, tweenArray) {
        let timeLine = gsap.timeline({scrollTrigger});
        tweenArray.map(tweenItem => {
            timeLine.add(tweenItem, ">");
        })
        return timeLine;
    }

    createTimeLineWithPosition(scrollTrigger, tweenArray) {
        let timeLine = gsap.timeline({scrollTrigger});
        tweenArray.map(tweenItem => {
            timeLine.add(tweenItem.tween, tweenItem.position || ">");
        })
        return timeLine;
    }

    createScrollTrigger(element, options = {}) {
        return {
            trigger: element,
            start: "bottom bottom", // when the top of the trigger hits the top of the viewport
            end: "+=200", // end after scrolling 200px beyond the start
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            ...options
        }
    }

    addClassScrollListener(element,options = {}){
        return gsap.to(element, {
            scrollTrigger: {
                trigger: element,
                start: "bottom+=500 bottom",
                end:"bottom top",
                scrub: true,
                once:true,
                ...options
            }
        })
    }

    createSideTitleDescTrigger(element, options = {}) {
        return {
            trigger: element,
            start: "top bottom", // when the top of the trigger hits the top of the viewport
            end: "+=200", // end after scrolling 200px beyond the start
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            ...options
        }
    }

    createTitleDescTrigger(element, options = {}) {
        return {
            trigger: element,
            start: "top bottom-=100", // when the top of the trigger hits the top of the viewport
            end: "+=200", // end after scrolling 200px beyond the start
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            ...options
        }
    }

    createMobileTitleDescTrigger(element, options = {}) {
        return {
            trigger: element,
            start: "bottom bottom", // when the top of the trigger hits the top of the viewport
            end: "+=200", // end after scrolling 200px beyond the start
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            ...options
        }
    }

    slideUpFadeIn(element, options = {}) {
        return gsap.from(
            element,
            {
                alpha: 0,
                y: "100",
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }

    slideDownFadeIn(element, options = {}) {
        return gsap.from(
            element,
            {
                alpha: 0,
                y: "-100",
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }

    slideTextUpFadeIn(element, options = {}) {
        return gsap.from(
            element,
            {
                alpha: 0,
                y: "30",
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }

    slideDown(element, options = {}) {
        return gsap.to(
            element,
            {
                y: "300",
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }

    alphaAnimation(element, options = {}) {
        return gsap.from(
            element,
            {
                alpha: 0,
                y: "0",
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_TWO,
                ...options
            })
    }

    scaleToOne(element, options = {}) {
        return gsap.from(
            element,
            {
                scaleX: 0.0001,
                scaleY: 0.0001,
                duration: DURATION_FAST,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }

    widthToZero(element, options = {}) {
        return gsap.to(
            element,
            {
                width: 0,
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }

    slideRightFadeIn(element, options = {}) {
        return gsap.from(
            element,
            {
                alpha: 0,
                x: "-100",
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }

    slideLeftFadeIn(element, options = {}) {
        return gsap.from(
            element,
            {
                alpha: 0,
                x: "100",
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }

    heightFromZero(element, options = {}) {
        return gsap.from(
            element,
            {
                height: 0,
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }

    slideCenterAlpha(element, options = {}) {
        return gsap.to(
            element,
            {
                x: "200",
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                alpha: 0,
                ...options
            })
    }

    count(element, num, reachNumber, changeNumber,options) {
        return gsap.to(
            num,
            {
                var: reachNumber,
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_TWO,
                onUpdate: changeNumber,
                ...options
            })

    }
    onlyAlphaAnimation(element, options = {}) {
        return gsap.from(
            element,
            {
                alpha: 0,
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_TWO,
                ...options
            })
    }
    clipPathAnimation(element, options = {}){
        return gsap.to(
            element,
            {
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_TWO,
                webkitClipPath:"polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                "clip-path":"polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                scale:1,
                ...options
            })
    }
    opacityAnimation(element, options = {}) {
        return gsap.fromTo(
            element,
             {
                alpha: 0,
                 ...options
            },{
                 opacity: 1,
                duration:DURATION_FAST,
                ease: EASE_TYPE_ONE,
                y:0
                
            }
        )
    }
    slideTextUpFadeInFromTo(element, options = {}) {
        return gsap.fromTo(
            element,
            {
                alpha: 0,

            },
            {
                opacity: 1,
                y: "0",
                duration: DURATION_NORMAL,
                ease: EASE_TYPE_ONE,
                ...options
            })
    }
    
}

export default ModelDetailTween;
