import React, {Component} from 'react';
import PageService from "../pageServices/pageService";
import {connect} from "react-redux";
import LoaderComponent from '../components/layout/loaderComponent';
class kvkkPage extends Component {
    constructor(props) {
        super(props);
        this.pageService = new PageService(this.props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    render() {
        let { cookieText } = this.props;
        return (
            <div className="cookie-root">
                { !cookieText && <LoaderComponent/>}
                <div className="cookie-container">
                {
                            cookieText &&
                    <div className="cookie-block">
                        
                                {cookieText.title != "" &&
                                    <h1>
                                        {cookieText.title}
                                    </h1>
                                }
                                {cookieText.sub_title != "" &&
                                    <h2>
                                        {cookieText.sub_title}
                                    </h2>
                                }
                                {
                                    cookieText.sub_descs && cookieText.sub_descs.length > 0 &&
                                    cookieText.sub_descs.map((item, key) =>
                                        <p key={key} dangerouslySetInnerHTML={{ __html: item }}></p>
                                    )}
                                
                                {
                                    cookieText.content && cookieText.content.length > 0 &&
                                    cookieText.content.map((item, key) =>
                                        <div className="cookie-item" key={key}>
                                            {(item.title && item.title != "") && <h3 >{item.title}</h3>}
                                            {(item.desc && item.desc !="") && <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>}
                                        </div>
                                    )}
                    </div>
                }
                    
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    cookieText: state.kvkkReducer[state.LangReducer.lang]&&state.kvkkReducer[state.LangReducer.lang]?state.kvkkReducer[state.LangReducer.lang] : null,
})
export default connect(mapStateToProps) (kvkkPage);