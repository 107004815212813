let isProd = true;
export const ASSETS = `/assets`;
export const WEB_SERVICE = isProd ?(typeof window === "undefined" ?  "https://kollektif.com.tr/data/":"/data/") : (typeof window === "undefined" ?  "https://kollektif.com.tr/data/":"http://localhost:3000/data/");
export const API_URL = isProd === true ? (typeof window !== "undefined" ? "/back/" : "http://localhost:5001/") : "http://localhost:5000/";
export const SITE_URL = "https://kollektif.com.tr";
export const TRLANG = "tr";
export const ENLANG = "en";
export const languages=[{label:"TR",value:"tr"},{label:"EN",value:"en"}];


// let isProd = false;
// export const ASSETS = `/assets`;
// export const WEB_SERVICE = isProd ?(typeof window === "undefined" ?  "https://ssrsite.kollektif.com.tr/data/":"/data/") : (typeof window === "undefined" ?  "https://ssrsite.kollektif.com.tr/data/":"http://localhost:3000/data/");
// export const API_URL = isProd === true ? (typeof window !== "undefined" ? "/back/" : "http://localhost:5001/") : "http://localhost:5000/";
// export const SITE_URL = "https://ssrsite.kollektif.com.tr";
// export const TRLANG = "tr";
// export const ENLANG = "en";
// export const languages=[{label:"TR",value:"tr"},{label:"EN",value:"en"}];